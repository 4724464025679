import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './prepareHeader';
import {
  Amount,
  BidAsk,
  BookingStatus,
  Currency1Version,
  Currency2Version,
  Product,
  Vacation,
  ValueDate,
} from '@/features/vacation/vacationModel';
import { vacationApi } from './vacation.api';
import { DateTime as LuxonDatetime } from 'luxon';
import { RepriceProps } from '@/components/booking/DealsActions/RepriceButton';
import { isDefined } from '@sgme/fp';
import { addErrorToShow, BackendError, StateBackendError } from '@/features/errors/errorsSlice';
import { InputsKey, updateInput } from '@/features/blotter/blotterSlice';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

// Define a service using a base URL and expected endpoints
export const dealsApi = createApi({
  reducerPath: 'dealsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${getConfig().apiUrl}/api/deals`, prepareHeaders }),
  tagTypes: ['Deals'],
  endpoints: (builder) => ({
    saveDeals: builder.mutation<SaveDealResponse[], SavePayload[]>({
      query(data) {
        return {
          url: '',
          method: 'PATCH',
          body: data,
        };
      },
      async onQueryStarted(_savePayload, { queryFulfilled, dispatch }) {
        try {
          const { data: savedDeals } = await queryFulfilled;

          const failedDeals = getBackendErrors(savedDeals);

          if (failedDeals.length > 0) {
            dispatch(addErrorToShow({ stateBackendErrors: failedDeals, dealsAction: 'SAVE' }));
          }
        } catch {
          // todo-Error_Mgt
        }
      },
    }),
    UpdateDeal: builder.mutation<UpdateDealResponse, UpdateDealPayload>({
      query(data) {
        return {
          url: '/update',
          method: 'PATCH',
          body: data,
        };
      },
      async onQueryStarted(_savePayload, { queryFulfilled, dispatch }) {
        try {
          const { data: savedDeals } = await queryFulfilled;

          const failedDeals = getBackendErrors([savedDeals]);

          if (failedDeals.length > 0) {
            dispatch(addErrorToShow({ stateBackendErrors: failedDeals, dealsAction: 'UPDATE' }));
          }
        } catch {
          // todo-Error_Mgt
        }
      },
    }),
    sendDeals: builder.mutation<SendDealResponse[], SendPayload[]>({
      query(data) {
        return {
          url: '/send',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(_sendDealPayload, { queryFulfilled, dispatch }) {
        try {
          const { data: sendedDeals } = await queryFulfilled;

          const failedDeals = getBackendErrors(sendedDeals);

          if (failedDeals.length > 0) {
            dispatch(addErrorToShow({ stateBackendErrors: failedDeals, dealsAction: 'SEND' }));
          }
        } catch {
          // todo-Error_Mgt
        }
      },
    }),
    discardDeals: builder.mutation<DiscardDealResponse[], DiscardPayload[]>({
      query(data) {
        return {
          url: '/discard',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(_discardDealPayload, { queryFulfilled, dispatch }) {
        try {
          const { data: discardedDeals } = await queryFulfilled;

          const failedDeals = getBackendErrors(discardedDeals);

          if (failedDeals.length > 0) {
            dispatch(addErrorToShow({ stateBackendErrors: failedDeals, dealsAction: 'DISCARD' }));
          }
        } catch {
          // todo-Error_Mgt
        }
      },
    }),
    refreshDeal: builder.mutation<RefreshDealResponse[], PrepareRefreshPayload[]>({
      query(data) {
        return {
          url: '/refresh',
          method: 'POST',
          body: formatMetadata(data),
        };
      },
      async onQueryStarted(unfilteredPayload, { queryFulfilled, dispatch }) {
        try {
          const { data: updatedDeals } = await queryFulfilled;

          const failedDeals = getBackendErrors(updatedDeals);

          if (failedDeals.length > 0) {
            dispatch(addErrorToShow({ stateBackendErrors: failedDeals, dealsAction: 'REFRESH' }));
          }

          dispatch(
            //update deals in cache - undefined is passed to match the getVacations call as per https://redux-toolkit.js.org/rtk-query/api/created-api/api-slice-utils#example-1
            vacationApi.util.updateQueryData('getVacations', undefined, (draft) => {
              unfilteredPayload.forEach((payload) => {
                updateDealsWithNewValues(draft, updatedDeals, payload, dispatch);
              });
            }),
          );
        } catch {
          // todo-Error_Mgt
        }
      },
    }),
    DeleteDeal: builder.mutation<DeleteDealResponse[], DeletePayload[]>({
      query(data) {
        return {
          url: '',
          method: 'DELETE',
          body: data,
        };
      },
      async onQueryStarted(_deleteDealPayload, { queryFulfilled, dispatch }) {
        try {
          const { data: deletedDeals } = await queryFulfilled;

          const failedDeals = getBackendErrors<DeleteDealResponse>(deletedDeals);

          if (failedDeals.length > 0) {
            dispatch(addErrorToShow({ stateBackendErrors: failedDeals, dealsAction: 'DELETE' }));
          }
        } catch {
          // todo-Error_Mgt
        }
      },
    }),
    convertDeals: builder.mutation<ConvertDealResponse[], ConvertPayload>({
      query(data) {
        return {
          url: '/convert',
          method: 'POST',
          body: data.deals,
        };
      },
      async onQueryStarted({ vacationId, currency2, currency1, deals, inputsChanged }, { queryFulfilled, dispatch }) {
        try {
          const { data: convertedDeals } = await queryFulfilled;

          const failedDeals = getBackendErrors(convertedDeals);

          if (failedDeals.length > 0) {
            dispatch(addErrorToShow({ stateBackendErrors: failedDeals, dealsAction: 'CONVERT' }));
          }

          dispatch(
            //update deals in cache - undefined is passed to match the getVacations call as per https://redux-toolkit.js.org/rtk-query/api/created-api/api-slice-utils#example-1
            vacationApi.util.updateQueryData('getVacations', undefined, (draft) => {
              updateDealsWithNewValues(draft, convertedDeals, { vacationId, currency2, currency1, deals, inputsChanged }, dispatch);
            }),
          );
        } catch {
          // todo-Error_Mgt
        }
      },
    }),
  }),
});

export const {
  useSaveDealsMutation,
  useUpdateDealMutation,
  useSendDealsMutation,
  useDiscardDealsMutation,
  useDeleteDealMutation,
  useRefreshDealMutation,
  useConvertDealsMutation,
} = dealsApi;

type CommonResponseMetaData = {
  dealId: string;
  status?: 'OK' | 'FAILED';
};

type ResponseWithPossiblyFailureReasons<T> = T &
  CommonResponseMetaData & {
    failureReasons?: BackendError[];
  };
type ResponseWithFailureReasons<T> = T &
  CommonResponseMetaData & {
    failureReasons: BackendError[];
  };

/**
 * Return a flatmap of BackendErrors
 * @param responseDeals
 * @returns
 */
function getBackendErrors<T>(
  responseDeals: ResponseWithPossiblyFailureReasons<T>[],
): Omit<StateBackendError, 'show'>[] {
  // Use to filter undefined response (typescript)
  const isFailureReason = (
    item: ResponseWithPossiblyFailureReasons<T> | undefined,
  ): item is ResponseWithFailureReasons<T> => !!item;

  const failedDeals = responseDeals
    .filter(
      (deal) =>
        deal.status === 'FAILED' || // Status failed doesn't exist on convert response only failureReasons is present
        (isDefined(deal.failureReasons) && deal.failureReasons.length > 0),
    )
    .filter(isFailureReason) // All failed response have mandatory failureReasons attribute
    .flatMap(({ dealId, failureReasons }) =>
      failureReasons.map((backendError) => ({ ...backendError, dealId })),
    );

  return failedDeals;
}

/**
 * Use to remove metadata that is usefull in handling response but not wanted in the request
 * Also we update format of the date to match prerequis backend
 * @param dataWithMetadata
 * @returns
 */
function formatMetadata(dataWithMetadata: PrepareRefreshPayload[]): RefreshPayload[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return dataWithMetadata.map(({ currency1, currency2, vacationId, proposedDate, ...rest }) => {
    const formattedProposedDate = LuxonDatetime.fromISO(proposedDate)
      .setLocale('en-US')
      .toFormat('yyyy-LL-dd');
    return { ...rest, proposedDate: formattedProposedDate } as RefreshPayload;
  });
}
const possibleInputKeys = ['spotRate.ask', 'spotRate.bid', 'swapPoint.ask', 'swapPoint.bid',]


function updateDealsWithNewValues(
  vacationStateDraft: Vacation[],
  dealsValuesToUpdate: RefreshDealResponse[] | ConvertDealResponse[],
  payload: DealPayloadMetaData,
  dispatch: ThunkDispatch<any, any, UnknownAction>
) {
  const { currency1, currency2, vacationId, inputsChanged } = payload;

  dealsValuesToUpdate.forEach((dealValues) => {
    const targetedVacation = vacationStateDraft.find((vacation) => vacation.id === vacationId);
    const targetedCurrencyPair = targetedVacation?.currencyPairs.find(
      (currencyPair) =>
        currencyPair.currency1 === currency1 && currencyPair.currency2 === currency2,
    );

    const targetedDeal = targetedCurrencyPair?.deals.find((deal) => deal.id === dealValues.dealId);

    targetedDeal!.originalAmount = dealValues.originalAmount;
    targetedDeal!.computedAmount = dealValues.computedAmount;
    targetedDeal!.marketRate = dealValues.marketRate;
    targetedDeal!.clientRate = dealValues.clientRate;

    if (isRefreshDealPayload(dealValues)) {
      targetedDeal!.spotRate = dealValues.spotRate;
      targetedDeal!.swapPoint = dealValues.swapPoint

      if (dispatch && targetedDeal) {
        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'spotRate.ask', value: dealValues?.spotRate.ask }))
        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'spotRate.bid', value: dealValues?.spotRate.bid }))
        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'swapPoint.ask', value: dealValues?.swapPoint.ask }))
        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'swapPoint.bid', value: dealValues?.swapPoint.bid }))

        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'amount.sell', value: (dealValues?.computedAmount as Currency1Version)?.currency1 || (dealValues.originalAmount as Currency1Version)?.currency1 }))
        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'amount.buy', value: (dealValues?.computedAmount as Currency2Version)?.currency2 || (dealValues.originalAmount as Currency2Version)?.currency2 }))
        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'marketRate', value: dealValues?.marketRate }))
        dispatch(updateInput({ dealId: dealValues.dealId, inputKey: 'clientRate', value: dealValues?.clientRate }))
      }
    }
    else {
      if (isDefined(inputsChanged)) {
        inputsChanged.forEach(input => {
          if (possibleInputKeys.includes(input)) {
            const [rate, way] = input.split(".") as ["spotRate" | "swapPoint", "bid" | "ask"]
            dispatch(updateInput({ dealId: dealValues.dealId, inputKey: input, value: (payload?.deals?.find(deal => deal.dealId === dealValues.dealId)?.[`${rate}`]?.[`${way}`]) as number }))
          }
        });
      }
    }

  });
}

const isRefreshDealPayload = (
  dealValues: RefreshDealResponse | ConvertDealResponse,
): dealValues is RefreshDealResponse => (dealValues as RefreshDealResponse).spotRate !== undefined;

// Save Deal
// Payload
export type SavePayload = {
  vacationId: string;
  dealId: string;
  currencyPairId: string;
  currencyPair: string;
  dealOwner: string; // sesameId from sgIAM
  originalAmount: Amount;
  computedAmount: Amount;
  spotRate: BidAsk;
  swapPoint: BidAsk;
  margin: number;
  marketRate: number;
  clientRate: number;
  product: Product;
  valueDate?: ValueDate
};

// Response
export type SaveDealResponse = SaveDealResponseSuccess & {
  failureReasons?: BackendError[];
};

export type SaveDealResponseSuccess = {
  dealId: string;
  status: 'OK';
};

// Update Deal

export type UpdateDealPayload = {
  dealId: string;
  currencyPairId: string;
  currencyPair: string;
  proposedValueDate: string;
};
// Response
export type UpdateDealResponse = UpdateDealResponseSuccess & {
  failureReasons?: BackendError[];
};

export type UpdateDealResponseSuccess = {
  dealId: string;
  status: 'OK';
  product: Product
};

// Send Deal
// Payload
export type SendPayload = {
  vacationId: string;
  dealId: string;
  counterparty: string;
  currencyPairId: string;
  currencyPair: string;
  dealOwner: string; // sesameId from sgIAM
  originalAmount: Amount;
  computedAmount: Amount;
  spotRate: BidAsk;
  swapPoint: BidAsk;
  margin: number;
  marketRate: number;
  clientRate: number;
  product: Product;
};

// Response
export type SendDealResponse = SendDealResponseSuccess & {
  failureReasons?: BackendError[];
};
export type SendDealResponseSuccess = {
  dealId: string;
  status: 'OK';
};

// Discard Deal
// Payload
export type DiscardPayload = {
  vacationId: string;
  dealId: string;
  counterparty: string;
  currencyPairId: string;
  currencyPair: string;
  dealOwner: string; // sesameId from sgIAM
  originalAmount: Amount;
  computedAmount: Amount;
  spotRate: BidAsk;
  swapPoint: BidAsk;
  margin: number;
  marketRate: number;
  clientRate: number;
  product: Product;
};

// Response
export type DiscardDealResponse = DiscardDealResponseSuccess & {
  failureReasons?: BackendError[];
};
export type DiscardDealResponseSuccess = {
  dealId: string;
  status: 'OK';
};

// RefreshDeal
// Payload
export type RefreshPayload = {
  dealId: string;
  product: Product;
  currencyPair: string;
  proposedDate: string;
};

export type PrepareRefreshPayload = RefreshPayload & DealPayloadMetaData;

type DealPayloadMetaData = {
  deals?: DealsPayload[];
  currency1: string;
  currency2: string;
  vacationId: string;
  inputsChanged?: InputsKey[];
};

// Delete Deal
// Payload
export type DeletePayload = {
  dealId: string;
  vacationId: string;
  dealOwner: string; // sesameId from sgIAM
  product: Product;
  currencyPairId: string;
};

// Response
export type DeleteDealResponse = DeletedDealResponseSuccess & {
  failureReasons?: BackendError[];
};
export type DeletedDealResponseSuccess = {
  dealId: string;
  status: 'OK';
};

type CommonDealResponse = {
  dealId: string;
  originalAmount: Amount;
  computedAmount: Amount;
  marketRate: number;
  clientRate: number;
};

// Response
export type RefreshDealResponse = CommonDealResponse & {
  spotRate: BidAsk;
  swapPoint: BidAsk;
} & { failureReasons?: BackendError[] };

export type RefreshDealResponseError = RefreshDealResponse & { failureReasons: BackendError[] };

// todo add error case
type ConvertDealResponse = ConvertDealResponseSuccess & {
  failureReasons?: BackendError[];
};

type ConvertDealResponseSuccess = CommonDealResponse;

export type ConvertPayload = {
  deals: DealsPayload[];
  vacationId: string;
  currency1: string;
  currency2: string;
  inputsChanged?: InputsKey[]
};

export type DealsPayload = {
  dealId: string;
  product: Product;
  spotRate: {
    bid: number;
    ask: number;
  };
  swapPoint: {
    bid: number;
    ask: number;
  };
};

export const updateStatusOnDeals = (deals: RepriceProps[], status: BookingStatus = 'TODO') =>
  vacationApi.util.updateQueryData('getVacations', undefined, (draftVacations) => {
    deals.forEach((deal) => {
      const currentVacation = draftVacations.find((vacation) => vacation.isCurrent);

      const targetedCurrencyPair = currentVacation?.currencyPairs.find(
        (currencyPair) =>
          currencyPair.currency1 === deal.currency1 && currencyPair.currency2 === deal.currency2,
      );

      const targetedDeal = targetedCurrencyPair?.deals.find(
        (draftDeal) => draftDeal.id === deal.dealId,
      );

      if (isDefined(targetedDeal)) {
        targetedDeal.status = status;
      }
    });
  });
