import { PrepareRefreshPayload, useRefreshDealMutation } from '@/api/deals.api';
import { FormEventHandler, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { useAppSelector } from '@/hooks/reduxHook';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

interface RefreshButtonProps {
  isFromBulkAction?: boolean;
}

function RefreshButton({
  deals,
  isFromBulkAction = false,
}: { deals: PrepareRefreshPayload[] } & RefreshButtonProps) {
  const [refreshDeal, { isLoading }] = useRefreshDealMutation();
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const onClick: FormEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      event.preventDefault();
      sgwtWebAnalytics?.trackEvent(isFromBulkAction ? 'Bulk Refresh' : 'Refresh', 'User actions');
      refreshDeal(deals);
    },
    [refreshDeal, deals, sgwtWebAnalytics, isFromBulkAction],
  );

  const buttonClasses = `btn btn-md ${isFromBulkAction ? ' btn-icon-start btn-outline-primary-alt py-1' : 'btn-default px-2'}`;

  return (
    <button
      role="button"
      data-e2e={`refresh-${deals.length !== 1 ? 'deals' : deals[0].dealId}`}
      className={buttonClasses}
      disabled={blotterReadOnly}
      onClick={onClick}
    >
      {!isLoading ? (
        <>
          <i role="img" className="icon icon-sm">
            refresh
          </i>
          {isFromBulkAction && <FormattedMessage id="blotter.refresh" />}
        </>
      ) : (
        <div data-e2e="spinner" className="spinner spinner-sm" />
      )}
    </button>
  );
}

export default RefreshButton;
