import DeselectButton from '@/components/booking/DealsBulkActionFooter/DeselectButton';
import SavedDealsSelectionButton from '@/components/booking/DealsBulkActionFooter/SavedDealsSelectionButton';
import { BookingStatus, Deal } from '@/features/vacation/vacationModel';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import RepriceButton, { RepriceProps } from '../DealsActions/RepriceButton';
import SendButton from '../DealsActions/SendButton';
import { DiscardPayload, SendPayload } from '@/api/deals.api';
import { getUserSesameId } from '@/features/user/userSlice';
import { useAppSelector } from '@/hooks/reduxHook';
import { DiscardButton } from '@/components/booking/DealsActions/DiscardButton';

interface SavedDealsBulkActionsFooterProps {
  selectAllSavedDeals: (status: BookingStatus[]) => void;
  deselectAllSavedDeals: () => void;
  selectButtonLabel: string;
  selectedSavedDeals: Deal[];
  currencyPairId: string;
}

const SavedDealsBulkActionsFooter: FC<SavedDealsBulkActionsFooterProps> = ({
  selectAllSavedDeals,
  deselectAllSavedDeals,
  selectButtonLabel,
  selectedSavedDeals,
  currencyPairId,
}) => {
  const { sessionId: vacationId, currency1, currency2 } = useParams();
  const dealOwner = useAppSelector((state) => getUserSesameId(state) ?? '');

  const repriceProps: RepriceProps[] = selectedSavedDeals.map((savedDeal) => ({
    dealId: savedDeal.id,
    currency1: currency1!,
    currency2: currency2!,
  }));

  const dealsToSend: SendPayload[] = selectedSavedDeals.map((savedDeal) => ({
    dealId: savedDeal.id,
    currencyPair: `${currency1}${currency2}`,
    originalAmount: savedDeal.originalAmount,
    computedAmount: savedDeal.computedAmount,
    spotRate: savedDeal.spotRate,
    swapPoint: savedDeal.swapPoint,
    margin: savedDeal.margin,
    marketRate: savedDeal.marketRate,
    clientRate: savedDeal.clientRate,
    product: savedDeal.product,
    dealOwner: dealOwner.toUpperCase(),
    currencyPairId,
    vacationId: vacationId!,
    counterparty: savedDeal.counterparty,
  }));

  const dealsToDiscard: DiscardPayload[] = selectedSavedDeals.map((savedDeal) => ({
    dealId: savedDeal.id,
    currencyPair: `${currency1}${currency2}`,
    originalAmount: savedDeal.originalAmount,
    computedAmount: savedDeal.computedAmount,
    spotRate: savedDeal.spotRate,
    swapPoint: savedDeal.swapPoint,
    margin: savedDeal.margin,
    marketRate: savedDeal.marketRate,
    clientRate: savedDeal.clientRate,
    product: savedDeal.product,
    dealOwner: dealOwner.toUpperCase(),
    currencyPairId,
    vacationId: vacationId!,
    counterparty: savedDeal.counterparty,
  }));

  return (
    <div className="d-flex p-2 bg-info justify-content-between">
      <SavedDealsSelectionButton
        selectAllSavedDeals={selectAllSavedDeals}
        selectButtonLabel={selectButtonLabel}
        isActive
      />

      <div className="d-flex gap-2">
        <DeselectButton onClick={deselectAllSavedDeals} />
        <DiscardButton deals={dealsToDiscard} isFromBulkAction />
        <RepriceButton deals={repriceProps} isFromBulkAction />
        <SendButton
          deals={dealsToSend}
          isFromBulkAction
          overrideClassName="btn btn-primary-alt py-1"
        />
      </div>
    </div>
  );
};

export default SavedDealsBulkActionsFooter;
