import { ChangeEvent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

interface SwitcherProps {
  label: string;
  id: string;
  onChange?: (value: boolean) => void;
  checked?: boolean;
}

export function Switcher({ onChange, checked, label, id }: SwitcherProps): JSX.Element {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.currentTarget.checked);
    },
    [onChange],
  );

  return (
    <div className="form-check form-switch d-flex align-items-center">
      <input
        onChange={handleChange}
        checked={checked}
        type="checkbox"
        className="form-check-input"
        id={id}
      />
      <label className="form-check-label" htmlFor={id}>
        <FormattedMessage id={label} />
      </label>
    </div>
  );
}
