import { TotalAmount } from '@/components/booking/TotalAmount';
import { Clock } from '@/components/shared/Clock';
import { DateKpi } from '@/components/shared/DateKpi';
import { PossibleSessionTime } from '@/features/vacation/vacationModel';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { SessionRemainingTimeCountDown } from '@/components/shared/SessionRemainingTimeCountDown';

const WrappedInABox = ({ children }: PropsWithChildren) => (
  <div style={{ minHeight: '68px', padding: '8px 16px 8px 16px' }} className="bg-lvl2">
    {children}
  </div>
);

const labelSessionTime: React.CSSProperties = {
  //styleName: Text - Semibold (600)/18;
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '28px',
  letterSpacing: '-0.015em',
  textAlign: 'left',
  color: '#757575',
};

type DealStatusBadge = {
  dealNumbers: number;
  sendDealNumbers: number;
};
export function DealsStatusBadge({ dealNumbers, sendDealNumbers }: DealStatusBadge) {
  const badgeClass =
    sendDealNumbers === 0
      ? 'badge-discreet-warning' // 0 deals sent
      : sendDealNumbers === dealNumbers
      ? 'badge-discreet-success' // all deals sent
      : 'badge-discreet-info'; // partially deals sent

  return (
    <div className={`badge badge-light rounded-pill ${badgeClass}`}>
      <FormattedMessage id={`badge.dealsSent`} values={{ dealNumbers, sendDealNumbers }} />
    </div>
  );
}

type BookingKpiProps = {
  currentSessionTime: PossibleSessionTime;
  currency1: string;
  currency2: string;
  dealNumbers: number;
  sendDealNumbers: number;
};

export function BookingKpi({
  currentSessionTime,
  currency1,
  currency2,
  dealNumbers,
  sendDealNumbers,
}: BookingKpiProps) {
  return (
    <div className="d-flex justify-content-between flex-wrap gap-3">
      {/* left block */}
      <div className="d-flex gap-8px flex-column">
        <span className="text-nowrap" style={labelSessionTime}>
          <FormattedMessage id="session" values={{ currentSessionTime }} />
        </span>
        <div className="d-flex gap-8px">
          <h1 className="text-nowrap" data-e2e="kpi-currency">
            {currency1} / {currency2}
          </h1>
          <div className="align-self-center d-flex flex-column align-items-end">
            <DealsStatusBadge dealNumbers={dealNumbers} sendDealNumbers={sendDealNumbers} />
          </div>
        </div>
      </div>
      {/* right block */}
      <div className="d-flex flex-wrap gap-3">
        <WrappedInABox>
          <TotalAmount />
        </WrappedInABox>
        <div className='d-flex gap-3'>
          <WrappedInABox>
            <DateKpi />
          </WrappedInABox>
          <WrappedInABox>
            <Clock labelId="currentTime" />
          </WrappedInABox>
          <WrappedInABox>
            <SessionRemainingTimeCountDown growlRightPosition={25} growlTopPosition={120} />
          </WrappedInABox>
        </div>
      </div>
    </div>
  );
}
