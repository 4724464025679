import { FormattedMessage } from 'react-intl';
import { SessionTable } from './SessionTable';
import { ClosedIcon } from '@/components/shared/ClosedIcon';
import Spinner from '@/components/shared/Spinner';
import { Vacation } from '@/features/vacation/vacationModel';

export type TableSessionsProps = {
  type: 'current' | 'past';
  isLoading: boolean;
  vacations: Vacation[];
};

/**
 * Wrapper to show a table for dedicated session table with good title
 * Allow to conditionnal show the closed icon depending props
 * @param param0
 * @returns
 */
function TableSessions({ type, isLoading, vacations }: TableSessionsProps) {
  let content = <ClosedIcon labelId={`no.${type}.session`} />;
  
  if (vacations.length > 0) {
    content = <SessionTable type={type} vacations={vacations} />;
  }

  return (
    <section data-e2e={`session-${type}`}>
      <h3>
        <FormattedMessage id={`${type}.session`} />
      </h3>
      {isLoading ? <Spinner /> : content}
    </section>
  );
}

export default TableSessions;
