import { getUserConnection } from '@/widgets/widgets';
import { isDefined } from '@sgme/fp';

export function getFakeUser(): string | undefined {
  const userConnection = getUserConnection();
  const params = new URLSearchParams(self.location.search);
  return (
    (params.has('user') && params.get('user')) ||
    (isDefined(userConnection) && isDefined(userConnection.mail) && userConnection.mail) ||
    undefined
  );
}
