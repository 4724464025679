import { isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { useAppSelector } from '@/hooks/reduxHook';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface DeselectButtonProps {
  onClick: () => void;
}
const DeselectButton: FC<DeselectButtonProps> = ({ onClick }) => {
  const { formatMessage } = useIntl();
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);

  return (
    <button className="btn btn-flat-white py-1" disabled={blotterReadOnly} onClick={onClick}>
      {formatMessage({ id: `blotter.dealsBulkAction.deselect` })}
    </button>
  );
};

export default DeselectButton;
