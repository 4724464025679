import { FormattedMessage } from "react-intl";

type ClosedIconProps = {
    labelId?: string;
}

export function ClosedIcon({labelId = 'session.closed'}: ClosedIconProps) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-secondary">
      <i role="img" className="icon icon-xl">timelapse</i>
      <h4>
        <FormattedMessage id={labelId} />
        </h4>
    </div>
  );
}

