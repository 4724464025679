import { useIntl } from 'react-intl';
import { FormRates } from '@/components/booking/FormRates';
import { DealToConvert } from '@/components/booking/Blotter/RatesColumnsHeader';

export const ApplySpotRateButton = ({
  selectedDeals: dealsToConvert,
}: {
  selectedDeals: DealToConvert[];
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormRates
      buttonClasses="btn btn-outline-primary-alt py-1"
      popoverPlacement="top"
      dealsToConvert={dealsToConvert}
    >
      {formatMessage({ id: `blotter.dealsBulkAction.applySpotRate` })}
    </FormRates>
  );
};
