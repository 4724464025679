import { Vacation } from '@/features/vacation/vacationModel';
import { FormattedMessage } from 'react-intl';
import { DateTime as LuxonDatetime } from 'luxon';

import { Link } from 'react-router-dom';

type SessionTableProps = {
  type: 'current' | 'past';
  vacations: Vacation[];
};

/**
 * Table that show rows for dedicated sessions
 * @param param0
 * @returns
 */
export function SessionTable({ type, vacations }: SessionTableProps) {
  return (
    <table
      data-e2e={`session-table-${type}`}
      className="table"
      style={{ tableLayout: 'fixed', width: '100%' }}
    >
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="table.timeSession" />
          </th>
          <th scope="col">
            <FormattedMessage id="table.nbDeals" />
          </th>
          <th scope="col">
            <FormattedMessage id="table.sessionId" />
          </th>
          <th scope="col">
            <FormattedMessage id="table.tradeDate" />
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {vacations.map(
          ({ tradeDate, sessionTime, id: sessionId, dealNumbers, currencyPairs }, index) => (
            <tr
              data-e2e={`row-${index}`}
              key={`${sessionTime}-${sessionId}`}
              className="align-middle"
            >
              <th scope="row">{sessionTime}</th>
              <td>
                <span className="badge badge-discreet-primary badge-md rounded-pill">
                  {dealNumbers}
                </span>
              </td>
              <td>{sessionId}</td>
              <td>{LuxonDatetime.fromISO(tradeDate).setLocale('en-US').toFormat('dd LLL yyyy')}</td>
              <td className="text-end text-secondary">
                {type === 'current' ? (
                  <Link
                    to={`sessions/${sessionId}/${currencyPairs[0].currency1}/${currencyPairs[0].currency2}`}
                    role="button"
                    className="btn btn-info btn-md btn-text-icon gap-2"
                  >
                    <FormattedMessage id="table.openSession" />
                    <i role="img" className="icon">
                      arrow_forward
                    </i>
                  </Link>
                ) : (
                  <Link
                    to={`sessions/${sessionId}/${currencyPairs[0].currency1}/${currencyPairs[0].currency2}`}
                    role="button"
                  >
                    <i role="img" className="icon">
                      arrow_forward
                    </i>
                  </Link>
                )}
              </td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
}
