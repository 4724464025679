import { useAppDispatch, useAppSelector } from '@/hooks/reduxHook';
import Growl from './Growl';
import { getErrorsToDisplay, hideError, removeErrorToShow } from '@/features/errors/errorsSlice';
import { useIntl } from 'react-intl';

function ErrorGrowl() {
  const growlRightPosition = 25;
  const growlTopPosition = 120; // Avoid 0
  const { formatMessage } = useIntl();
  const errorsToDisplay = useAppSelector(getErrorsToDisplay);
  const appDispatch = useAppDispatch();

  const closeGrowl = (stateId: string) => {
    appDispatch(removeErrorToShow(stateId));
  };

  const autocloseAction = (stateId: string) => {
    appDispatch(hideError(stateId));
  };

  return (
    <>
      {Object.entries(errorsToDisplay)
        .filter(([_stateId, { show }]) => show)
        .map(([stateId, { errorId, dealId }], index) => (
          <Growl
            key={stateId}
            bodyMessage={formatMessage({ id: `error.${errorId}.body` }, { dealId })}
            title={`${formatMessage({ id: `error.${errorId}` })} - ${errorId}`}
            onClose={() => closeGrowl(stateId)}
            testId={`error-growl-${index}`}
            titleColor="danger"
            right={growlRightPosition}
            top={growlTopPosition * (index + 1)}
            autocloseGrowl={() => autocloseAction(stateId)}
          />
        ))}
    </>
  );
}

export default ErrorGrowl;
