import Panel from '@/components/booking/Panel';
import { Footer } from '@/layout/Footer';
import { Header } from '@/layout/Header';
import { Outlet } from 'react-router-dom';
import './Booking.css';
import { useGetVacationsQuery } from '@/api/vacation.api';
import { useNotifications } from '@/hooks/useNotifications';

function Booking() {
  useGetVacationsQuery();
  useNotifications();

  return (
    <div className="booking">
      <Header />
      <Panel />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Booking;
