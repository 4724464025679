import TableSessions from './TableSessions';
import { isDefined, isNotDefined } from '@sgme/fp';
import { useGetVacationsQuery } from '@/api/vacation.api';
import { ClosedIcon } from '@/components/shared/ClosedIcon';

function Sessions() {
  const { isFetching: isFetchingVacations, data: vacations } = useGetVacationsQuery();

  const currentVacation = vacations?.find((vacation) => vacation.isCurrent);
  const pastVacations = vacations?.filter((vacation) => !vacation.isCurrent) || [];
  /**
   * Still fetching the data && no vacations at all === session closed
   * @TODO improve callback
   */
  const sessionsAreClosed =
    !isFetchingVacations && isNotDefined(currentVacation) && pastVacations?.length === 0;

  return (
    <div data-e2e="sessions" className="container mt-32px">
      {sessionsAreClosed ? (
        <ClosedIcon />
      ) : (
        <>
          <TableSessions
            type="current"
            isLoading={isFetchingVacations}
            vacations={isDefined(currentVacation) ? [currentVacation] : []}
          />
          <div className="mt-32px">
            <TableSessions
              type="past"
              isLoading={isFetchingVacations}
              vacations={pastVacations ?? []}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Sessions;
