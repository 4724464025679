import { updateStatusOnDeals } from '@/api/deals.api';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHook';
import { FormEventHandler, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

export type RepriceProps = {
  dealId: string;
  currency1: string;
  currency2: string;
  isFromBulkAction?: boolean;
};

function RepriceButton({
  deals,
  isFromBulkAction = false,
}: { deals: RepriceProps[] } & { isFromBulkAction?: boolean }) {
  const dispatch = useAppDispatch();
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const onReprice: FormEventHandler<HTMLButtonElement> = useCallback(() => {
    sgwtWebAnalytics?.trackEvent(isFromBulkAction ? 'Bulk Reprice' : 'Reprice', 'User actions');
    dispatch(updateStatusOnDeals(deals));
  }, [deals, dispatch, sgwtWebAnalytics, isFromBulkAction]);

  const buttonClasses = `btn btn-md ${isFromBulkAction ? ' btn-icon-start btn-outline-primary-alt py-1' : 'btn-default px-2'}`;

  return (
    <button
      role="button"
      data-e2e={`reprice-${deals.length !== 1 ? 'deals' : deals[0].dealId}`}
      className={buttonClasses}
      disabled={blotterReadOnly}
      onClick={onReprice}
    >
      <i role="img" className="icon icon-sm">
        edit
      </i>
      {isFromBulkAction && <FormattedMessage id="blotter.reprice" />}
    </button>
  );
}

export default RepriceButton;
