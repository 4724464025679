import { PropsWithChildren } from 'react';
import { IHeaderGroupParams } from 'ag-grid-community';
import {
  BlotterUrlParameters,
  Deal,
  openStatus,
  ValueDate,
} from '@/features/vacation/vacationModel';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks/reduxHook';
import { selectCurrencyPairDealsFromSessionIdVacation } from '@/features/vacation/vacationSelectors';
import { FormRates } from '@/components/booking/FormRates';
import { useIntl } from 'react-intl';
import { DealsPayload } from '@/api/deals.api';

export type DealToConvert = DealsPayload & { valueDate?: Pick<ValueDate, 'proposedDate'> };

export const RatesColumnsHeader = ({
  displayName,
}: PropsWithChildren<IHeaderGroupParams<Deal>>) => {
  const { formatMessage } = useIntl();
  const { sessionId: vacationId, currency1, currency2 } = useParams() as BlotterUrlParameters;
  const currencyPairWithDeals = useAppSelector((state) =>
    selectCurrencyPairDealsFromSessionIdVacation(state, vacationId, { currency1, currency2 }),
  );

  const isSwapPointColumn = displayName === formatMessage({ id: 'blotter.swap.point' });

  const currencyPairDeals = currencyPairWithDeals?.deals ?? [];

  const dealsToConvert = currencyPairDeals.reduce<DealToConvert[]>(
    (dealsToConvertArray: DealToConvert[], deal) => {
      const { id: dealId, product, spotRate, swapPoint } = deal;
      const valueDate = isSwapPointColumn ? deal.valueDate : undefined;
      const dealToConvert = {
        dealId,
        product,
        spotRate,
        swapPoint,
        valueDate,
      };

      const isValidSwapPointDeal = isSwapPointColumn ? deal.product === 'FORWARD' : true;

      if (openStatus.includes(deal.status) && isValidSwapPointDeal) {
        dealsToConvertArray.push(dealToConvert);
      }

      return dealsToConvertArray;
    },
    [],
  );

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="">{displayName}</div>
      <FormRates
        buttonClasses="btn btn-default btn-sm btn-icon-start"
        popoverPlacement="bottom"
        dealsToConvert={dealsToConvert}
        isSwapPointColumn={isSwapPointColumn}
      >
        <i className="icon">add</i>
        {formatMessage({ id: 'blotter.columnsHeader.rates.applyAll' })}
      </FormRates>
    </div>
  );
};
