// src/mocks/handlers.js
import { delay, http } from 'msw';
import vacations from '@/mocks/vacations.json';
import saveDeals from '@/mocks/saveDeals.json';
import refreshDealsErrors from '@/mocks/refreshDealsErrors.json';
import convertErrors from '@/mocks/convertError.json';
import { getConfig } from '@/config/config';

const apiUrl = getConfig().apiUrl;

export const handlers = [
  // If authenticated, return a mocked user details
  http.get(`${apiUrl}/api/vacations`, () => new Response(JSON.stringify(vacations))),

  // If authenticated, return a mocked user details
  http.patch(`${apiUrl}/api/deals`, async () => {
    await delay(5000);
    return new Response(JSON.stringify(saveDeals));
  }),

  // If authenticated, return a mocked user details
  http.post(`${apiUrl}/api/deals/refresh`, () => new Response(JSON.stringify(refreshDealsErrors))),

  // If authenticated, return a mocked user details
  http.post(`${apiUrl}/api/deals/convert`, async () => {
    await delay(500);
    return new Response(JSON.stringify(convertErrors));
  }),
];
