import { dealsApi } from '@/api/deals.api';
import { rtkQueryErrorLogger } from '@/api/rtkQueryErrorHandler';
import { vacationApi } from '@/api/vacation.api';
import blotterReducer from '@/features/blotter/blotterSlice';
import errorsReducer from "@/features/errors/errorsSlice";
import streamingReducer from '@/features/streaming/streamingSlice';
import userReducer from '@/features/user/userSlice';
import vacationReducer from '@/features/vacation/vacationSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

const rootReducer = combineReducers({
  vacation: vacationReducer,
  streaming: streamingReducer,
  blotter: blotterReducer,
  user: userReducer,
  errors: errorsReducer,
  [vacationApi.reducerPath]: vacationApi.reducer,
  [dealsApi.reducerPath]: dealsApi.reducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(vacationApi.middleware, dealsApi.middleware, rtkQueryErrorLogger),
  });
}

setupListeners(setupStore().dispatch);

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
