import { getConfig } from '@/config/config';
import { logger } from '@/core/logger';
import ErrorPage from '@/pages/ErrorPage';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';
import React, { ReactNode } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sg-error-page': {
        code: 404 | 500 | 503;
        theme?: 'dark' | 'standard';
        'action-button-label'?: string;
        'action-button-link'?: string;
        'contact-us'?: 'help-center' | string;
        fullscreen?: boolean;
        'description-label'?: string;
        'title-label'?: string;
      };
    }
  }
}

interface IErrorBoundaryState {
  error: Error | null;
}

interface IErrorBoundaryProps {
  children: ReactNode;
}

/**
 * Need to be a class component
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * Need to use less possible outside module to catch all error
 */
export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(errorDetected: Error, errorInfo: React.ErrorInfo) {
    logger.logError(
      `ErrorBoundary error[${errorDetected.message}] stack[${errorDetected.stack}] errorInfo[${errorInfo}]`,
      errorDetected,
    );
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div style={{ height: '100vh' }}>
          <SgwtHelpCenter
            applicationId={import.meta.env.VITE_APP_ID as string}
            mailSubject={`[SGME-LMAFOREX:${getConfig().env.toUpperCase()}] Help center message`}
            messageOnly
            messageTemplate={`Hello support.

    I encountered an issue while using the service.
    -----------------
    Technical details
    Error message: ${error.message}
    Stack trace: ${error.stack}
                `}
          />
          <ErrorPage descriptionLabel={error.message} fullScreen={true} />;
        </div>
      );
    }

    return children;
  }
}
