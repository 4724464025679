import { LogBuilder } from '@sgme/logger';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import { getConfig } from '@/config/config';

const appConfig = getConfig();
const isProd = appConfig?.env === 'prod';
const logBuilder = LogBuilder();

if (isProd) {
  logBuilder
    .enableServerLogs({
      url: `${appConfig.apiUrl}/api/log`,
      prefix: '[LMAForex]', //todo check it matches needed index ?
      storeName: 'main',
    })
    .setAuthTokenFactory(() => {
      if (sgwtConnect.isAuthorized()) {
        const authToken = sgwtConnect.getAuthorizationHeader();
        if (authToken) return authToken;
      }
      throw new Error('No authorization header or token. Disconnected ?!');
    });
} else {
  logBuilder.enableConsole();
}

export const logger = logBuilder.build();
