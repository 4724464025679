import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Vacation } from './vacationModel';

interface VacationState {
  lastVacation: Vacation | null;
}

const initialState = { lastVacation: null } as VacationState;

const vacationSlice = createSlice({
  name: 'vacation',
  initialState,
  reducers: {
    setLastVacation(state, action: PayloadAction<Vacation | null>) {
      state.lastVacation = action.payload;
    },
  },
});

export const { setLastVacation } = vacationSlice.actions;

export default vacationSlice.reducer;
