import { BlotterUrlParameters, BookingStatus } from '@/features/vacation/vacationModel';
import { NavLink, useParams } from 'react-router-dom';
import { StatusBadge } from './StatusBadge';
import { useAppDispatch } from '@/hooks/reduxHook';
import { resetSelectedRows } from '@/features/blotter/blotterSlice';

export type PanelItemProps = {
  currency1: string;
  currency2: string;
  numberOfDeals: number;
  numberOfAlreadyBookedDeals: number;
  status: BookingStatus;
  dealOwner: string;
  sessionId: string;
};

export function PanelItem({
  currency1,
  currency2,
  numberOfDeals,
  numberOfAlreadyBookedDeals,
  status,
  sessionId,
  dealOwner,
}: PanelItemProps) {
  const appDispatch = useAppDispatch();
  const { currency1: actualCurrency1, currency2: actualCurrency2 } = useParams() as BlotterUrlParameters;
  const handleClickCurrency = () => {
    if (currency1 !== actualCurrency1 || currency2 !== actualCurrency2) {
      // I go to an other page need to reset state on selected rows
      appDispatch(resetSelectedRows());
    }
  }
  return (
    <NavLink
      to={`/sessions/${sessionId}/${currency1}/${currency2}`}
      data-e2e={`panel-item-${sessionId}`}
      onClick={handleClickCurrency}
      className={({ isActive }: { isActive: boolean }) =>
        `${isActive ? 'active' : ''} text-decoration-none border-bottom`
      }
    >
      <li className="d-flex justify-content-between list-group-item list-group-item-action">
        <div>
          <div className="fw-bold">{`${currency1}/${currency2}`}</div>
          <div className="fw-normal text-secondary pt-8px">{`${numberOfAlreadyBookedDeals}/${numberOfDeals} deals`}</div>
        </div>
        <div className="d-flex flex-column align-items-end">
          <StatusBadge status={status} />
          <div className="fw-normal text-secondary pt-8px">{dealOwner}</div>
        </div>
      </li>
    </NavLink>
  );
}
