import { sgwtConnect } from '@/widgets/sgwtConnect';
import { isDefined } from '@sgme/fp';
import { v4 } from 'uuid';
import { getFakeUser } from './fakeUser';

export function prepareHeaders(headers: Headers): Headers {
  const authorizationHeader = sgwtConnect.getAuthorizationHeader();
  if (isDefined(authorizationHeader)) {
    headers.set('Authorization', authorizationHeader);
  }

  const fakeUser = getFakeUser();
  if (isDefined(fakeUser)) {
    headers.set('X-Fake-Authentication', fakeUser);
  }

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  headers.set('X-Correlation-ID', v4());

  return headers;
}
