import { getErrorDealById } from '@/features/errors/errorsSlice';
import { BookingStatus } from '@/features/vacation/vacationModel';
import { useAppSelector } from '@/hooks/reduxHook';
import { isDefined } from '@sgme/fp';

export function SmallStatusBadge({ value, dealId }: { value: BookingStatus; dealId: string }) {
  const errorDeal = useAppSelector((state) => getErrorDealById(state, dealId));

  function getStatus(status: string) {
    switch (status) {
      case 'SENT':
        return { color: 'text-success', icon: 'check_circle' };
      case 'UNSUCCESSFUL':
      case 'DELETED':
        return { color: 'text-danger', icon: 'highlight_off' };
      case 'DONE':
        return { color: 'text-info', icon: 'done_all' };
    }
  }

  const status = getStatus(value);

  return (
    <>
      {['SENT', 'UNSUCCESSFUL', 'DELETED', 'DONE'].includes(value) && (
        <i
          role="img"
          data-e2e={`status-badge-${value}`}
          className={`self-align-center icon ${status?.color ?? ''}`}
        >
          {status?.icon ?? ''}
        </i>
      )}
      {isDefined(errorDeal) ? (
        <i
          role="img"
          data-e2e={`error-badge-${dealId}`}
          className={`self-align-center icon text-danger`}
        >
          flag
        </i>
      ) : (
        <></>
      )}
    </>
  );
}

export function smallStatusBadgeRenderSelector(
  dealId: string | undefined,
  bookingStatus: undefined | BookingStatus,
) {
  if (isDefined(dealId) && isDefined(bookingStatus)) {
    return {
      component: SmallStatusBadge,
      params: {
        dealId,
        bookingStatus,
      },
    };
  }
  return {
    component: () => null,
  };
}
