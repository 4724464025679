import { FC } from 'react';
import { isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { useAppSelector } from '@/hooks/reduxHook';
import { BookingStatus } from '@/features/vacation/vacationModel';

interface OpenDealsSelectionButtonProps {
  selectAllOpenDeals: (status: BookingStatus[]) => void;
  selectButtonLabel: string;
  isActive?: boolean;
}
const OpenDealsSelectionButton: FC<OpenDealsSelectionButtonProps> = ({
  selectAllOpenDeals,
  selectButtonLabel,
  isActive,
}) => {
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);

  return (
    <button
      data-e2e={'open-deals-selection-btn'}
      className={`btn ${isActive ? 'btn-primary-alt ' : 'btn-outline-primary'} py-1 px-3 rounded-pill `}
      disabled={blotterReadOnly}
      onClick={() => selectAllOpenDeals(['TODO', 'INPROGRESS'])}
    >
      {selectButtonLabel}
    </button>
  );
};

export default OpenDealsSelectionButton;
