import { DateTime as LuxonDatetime } from 'luxon';
import { FormattedMessage } from 'react-intl';

export type DateKpiProps = {
  local?: 'en-US' | 'fr';
};

const labelStyle: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0em',
  // verticalAlign: 'center'
};

export function DateKpi({ local = 'en-US' }: DateKpiProps) {
  const now = LuxonDatetime.now().setLocale(local)
    .toFormat('dd LLL yyyy');
  return (
    <div data-e2e="date-todaysDate" className="d-flex flex-column gap-2" style={{ minWidth: '130px' }}>
      <span className="text-secondary" style={labelStyle}>
        <FormattedMessage id="todaysDate" />
      </span>
      <h4 className="text-nowrap">{now}</h4>
    </div>
  );
}