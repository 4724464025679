import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

export const CurrentTimeContext = createContext<string>('');

export const CurrentTimeContextProvider = ({ children }: PropsWithChildren) => {
  const now = DateTime.now().toLocaleString(DateTime.TIME_24_WITH_SECONDS);
  const [currenTime, setCurrentTime] = useState(now);
  useEffect(() => {
    setInterval(() => {
      // this can't use now reference as it is a stable one
      setCurrentTime(DateTime.now().toLocaleString(DateTime.TIME_24_WITH_SECONDS));
    }, 1000);
  }, []);
  return <CurrentTimeContext.Provider value={currenTime}>{children}</CurrentTimeContext.Provider>;
};
