import { useIntl } from 'react-intl';

export type SgErrorPageProps = {
  code: 404 | 500 | 503;
  actionButtonLabel: string;
  descriptionLabel: string;
  titleLabel: string;
  fullScreen: boolean;
  contactUs: string;
  actionButtonLink: URL;
  theme?: 'dark' | 'standard';
};

export default function ErrorPage({
  descriptionLabel,
  fullScreen = false,
}: {
  descriptionLabel?: string;
  fullScreen?: boolean;
}) {
  const { formatMessage } = useIntl();

  const descriptionError = descriptionLabel
    ? descriptionLabel
    : formatMessage({ id: 'errorPage.descriptionLabel' });
  const actionButtonLabel = formatMessage({ id: 'errorPage.actionButtonLabel' });
  const titleLabel = formatMessage({ id: 'errorPage.titleLabel' });

  return (
    <sg-error-page
      code={500}
      action-button-label={actionButtonLabel}
      description-label={descriptionError}
      title-label={titleLabel}
      action-button-link="/"
      contact-us="help-center"
      fullscreen={fullScreen}
    />
  );
}
