import { useIntl } from 'react-intl';

export function AccountCenter(): JSX.Element {
  const intl = useIntl(); 

  return (
    <sgwt-account-center
      application-id="sgmarkets-lmaforex"
      authentication="sg-connect-v2"
      available-languages="fr,en"
      language={intl.locale}
      mode="sg-markets"
    />
  );
}
