import { BookingStatus, Product } from '@/features/vacation/vacationModel';
import { FormattedMessage } from 'react-intl';

export const StatusBadge = ({ status }: { status: BookingStatus | Product }) => {
  let badgeClass = '';

  switch (status) {
    case 'COLLECTED':
    case 'COLLECTING':
    case 'TODO':
      badgeClass = 'badge-light  badge-discreet-warning';
      break;
    case 'INPROGRESS':
    case 'DONE': // Save status
      badgeClass = 'badge-light  badge-discreet-info';
      break;
    case 'SENT':
      badgeClass = 'badge-light  badge-discreet-success';
      break;
      /**Produit */
    case 'SPOT':
      badgeClass = 'badge-outline-primary';
      break;
    case 'FORWARD':
    default:
      badgeClass = 'badge-primary';
      break;
  }

  return (
    <div className={`badge rounded-pill ${badgeClass}`}>
      <FormattedMessage id={`badge.${status}`} />
    </div>
  );
};
