import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/state/store';

interface UserState {
  sesameId: string;
  permission: string;
}

const initialState = { sesameId: '' } as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      return {
        ...state,
        sesameId: action.payload.sesameId,
        permission: action.payload.permission,
      };
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;

export const getUserSesameId = (state: RootState) => state.user.sesameId;

export const getPermission = (state: RootState) => state.user.permission;
