import { HelpCenter } from '@/core/sgwt/HelpCenter';
import { MiniFooter } from '@/core/sgwt/MiniFooter';

export function Footer() {
  return (
    <footer>
      <HelpCenter />
      <MiniFooter />
    </footer>
  );
}