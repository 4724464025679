import OpenDealsSelectionButton from '@/components/booking/DealsBulkActionFooter/OpenDealsSelectionButton';
import SavedDealsSelectionButton from '@/components/booking/DealsBulkActionFooter/SavedDealsSelectionButton';
import SentDealsSelectionActionButton from '@/components/booking/DealsBulkActionFooter/SentDealsSelectionButton';
import { BookingStatus } from '@/features/vacation/vacationModel';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface DefaultBulkActionsFooterProps {
  selectAllOpenDeals: (status: BookingStatus[]) => void;
  selectAllSavedDeals: (status: BookingStatus[]) => void;
  selectAllSentDeals: (status: BookingStatus[]) => void;
  openDealsCount: number;
  sentDealsCount: number;
  savedDealsCount: number;
}
const DefaultBulkActionsFooter: FC<DefaultBulkActionsFooterProps> = ({
  selectAllOpenDeals,
  selectAllSavedDeals,
  selectAllSentDeals,
  openDealsCount,
  sentDealsCount,
  savedDealsCount,
}) => {
  const { formatMessage } = useIntl();
  const messagePrefix = 'blotter.dealsBulkAction';
  
  return (
    <div className="d-flex gap-2 m-2" data-e2e={'bulk-action-footer'}>
      {openDealsCount > 0 && (
        <OpenDealsSelectionButton
          selectAllOpenDeals={selectAllOpenDeals}
          selectButtonLabel={formatMessage(
            { id: `${messagePrefix}.openDeals` },
            { count: openDealsCount },
          )}
        />
      )}
      {savedDealsCount > 0 && (
        <SavedDealsSelectionButton
          selectAllSavedDeals={selectAllSavedDeals}
          selectButtonLabel={formatMessage(
            { id: `${messagePrefix}.savedDeals` },
            { count: savedDealsCount },
          )}
        />
      )}
      {sentDealsCount > 0 && (
        <SentDealsSelectionActionButton
          selectAllSentDeals={selectAllSentDeals}
          selectButtonLabel={formatMessage(
            { id: `${messagePrefix}.sentDeals` },
            { count: sentDealsCount },
          )}
        />
      )}
    </div>
  );
};

export default DefaultBulkActionsFooter;
