type possibleHour = 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
type possibleMinutes = 15 | 45;

export type SessionTimeFormat = `${number}:${number}`;
export type PossibleSessionTime = `${possibleHour}:${possibleMinutes}` | '17:15';

export type ClosedVacationEventData = {
  vacationId: string;
};

export interface Vacation {
  id: string;
  sessionTime: PossibleSessionTime;
  isCurrent: boolean;
  tradeDate: string;
  beginDate: string;
  endDate: string;
  pricingDate: string;
  dealNumbers: number;
  status: BookingStatus;
  currencyPairs: CurrencyPair[];
}

export type BookingStatus = (typeof bookingStatus)[number];
export type Product = 'SPOT' | 'FORWARD';

export const bookingStatus = [
  'TODO',
  'INPROGRESS',
  'SENT',
  'UNSUCCESSFUL',
  'DELETED',
  'COLLECTING',
  'COLLECTED',
  'DONE', // it's "SAVED" status
] as const;
export const openStatus: BookingStatus[] = ['TODO', 'COLLECTING', 'COLLECTED', 'INPROGRESS'];
export type OpenStatus = (typeof openStatus)[number];

export interface CurrencyPair {
  currency1: string;
  currency2: string;
  id: string;
  dealOwner: string;
  status: BookingStatus;
  amount: {
    currency1: number;
    currency2: number;
  };
  dealNumbers: number;
  xoneDealNumbers: number;
  deals: Deal[];
}

export type Currency1Version = {
  currency1: number;
};

export type Currency2Version = {
  currency2: number;
};

export type Amount = Currency1Version | Currency2Version;

export interface BidAsk {
  bid: number;
  ask: number;
}

export interface Deal {
  id: string;
  counterparty: string;
  product: Product;
  originalAmount: Amount;
  computedAmount: Amount;
  valueDate: ValueDate;
  spotRate: BidAsk;
  swapPoint: BidAsk;
  margin: number;
  marketRate: number;
  clientRate: number;
  status: BookingStatus;
}

export interface ValueDate {
  theoreticalDate: string;
  proposedDate: string;
}

export type BlotterUrlParameters = {
  sessionId: string;
  currency1: string;
  currency2: string;
};
