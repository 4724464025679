import { isNotDefined } from '@sgme/fp';
import { NumberInput } from '@/components/booking/NumberInput';
import { ICellRendererParams } from 'ag-grid-community';
import { Deal } from '@/features/vacation/vacationModel';

export const rateCellRendererSelector = (
  // this is to override data type undefined inherited from agGRid
  // only case where Data property can be undefined is row grouping or loading infinite row models as per their doc
  // we don't use any of them
  params: ICellRendererParams<Deal> & { data: Deal },
  isCurrent: boolean,
  convertMetaDataPayload: { vacationId: string; currency1: string; currency2: string },
) => {
  const { data, colDef } = params;

  const { vacationId, currency1, currency2 } = convertMetaDataPayload;
  const isReadOnly =
    !isCurrent || ['SENT', 'DONE', 'UNSUCCESSFUL', 'DELETED'].includes(data.status);

  if (isNotDefined(data)) {
    throw new Error('current feature implementation relies on data being always present');
  }

  const fieldName = colDef!.field;

  // we do not display swap points when the deal is for a spot
  if (fieldName?.startsWith('swapPoint') && data.product === 'SPOT') {
    return {
      component: () => null,
    };
  }

  return {
    component: NumberInput,
    params: {
      readonly: isReadOnly,
      fieldName: colDef!.field,
      convertMetaDataPayload: { vacationId, currency1, currency2 },
    },
  };
};
