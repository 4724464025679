import { useCallback, useState } from 'react';

type DiscardButtonProps = {
  deals: DiscardPayload[];
  isFromBulkAction?: boolean;
};

import { DiscardPayload, useDiscardDealsMutation } from '@/api/deals.api';
import { useAppSelector } from '@/hooks/reduxHook';
import { isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

export function DiscardButton({ deals, isFromBulkAction = false }: DiscardButtonProps) {
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [discardDeals, { isLoading }] = useDiscardDealsMutation();

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleClick: React.FormEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      event.preventDefault();
      sgwtWebAnalytics?.trackEvent(isFromBulkAction ? 'Bulk Discard' : 'Discard', 'User actions');
      setShowModal(false);
      await discardDeals(deals);
    },
    [discardDeals, deals, sgwtWebAnalytics, isFromBulkAction],
  );

  const buttonClasses = `btn ${isFromBulkAction ? 'btn-icon-start btn-outline-primary-alt py-1' : 'btn-link px-1'}`;

  return (
    <>
      {!isLoading ? (
        <button
          role="button"
          data-e2e={`discard-${deals.length !== 1 ? 'deals' : deals[0].dealId}`}
          className={buttonClasses}
          onClick={handleShow}
          disabled={blotterReadOnly}
        >
          <i role="img" className="icon icon-sm">
            close
          </i>
          {isFromBulkAction && <FormattedMessage id={'blotter.dealsBulkAction.discard'} />}
        </button>
      ) : (
        <div data-e2e="spinner" className="spinner spinner-sm" />
      )}

      <Modal
        show={showModal}
        onHide={handleClose}
        contentClassName="border border-danger"
        data-e2e="discard-deal-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <div>
              <i role="img" className="icon text-danger mb-4">
                error_outline
              </i>
            </div>
            <span className="text-danger">
              <FormattedMessage
                id={'blotter.dealsBulkAction.discard.modal.title'}
                values={{ count: deals.length }}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id={'blotter.dealsBulkAction.discard.modal.body'}
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            onClick={handleClose}
            data-e2e="discard-deal-modal-cancel-button"
          >
            <FormattedMessage id={'blotter.dealsBulkAction.discard.modal.button.close'} />
          </button>
          <button
            className="btn btn-danger"
            onClick={handleClick}
            data-e2e="discard-deal-modal-confirm-button"
          >
            <FormattedMessage
              id={'blotter.dealsBulkAction.discard.modal.button.discardDeal'}
              values={{ count: deals.length }}
            />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
