import { selectCurrencyPairDealsFromSessionIdVacation } from '@/features/vacation/vacationSelectors';
import { useAppSelector } from '@/hooks/reduxHook';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Deal } from '@/features/vacation/vacationModel';

const labelStyle: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0em',
};

const strongLabelStyle: React.CSSProperties = {
  fontFamily: 'Inter',
  fontSize: '21px',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '-0.015em',
  textAlign: 'center',
};

const strongAmoutStyle: React.CSSProperties = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0em',
  textAlign: 'right',
};

const normalAmountStyle: React.CSSProperties = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0em',
  textAlign: 'right',
};

const formatNumeric = (num: number, isMillion: boolean): string => {
  const isPositive = num > 0;

  // show in M if amounts are high, otherwise show in K
  if (!isMillion) {
    return `${isPositive ? '+' : ''}${(num / 1000).toFixed(2)}K`;
  }

  return `${isPositive ? '+' : ''}${(num / 1000000).toFixed(2)}M`;
};

const fixAmounts = (amount: number, digits: number): number => parseFloat(amount.toFixed(digits));

const addAmounts = (amount1: number, amount2: number): number =>
  (fixAmounts(amount1, 2) * 100 + fixAmounts(amount2, 2) * 100) / 100;

const getAmountsByCurrency = (deals: Deal[]): number[] =>
  deals.reduce(
    (amounts, deal) => {
      if ('currency1' in deal.originalAmount && 'currency2' in deal.computedAmount) {
        amounts[0] = addAmounts(amounts[0], deal.originalAmount.currency1);
        amounts[1] = addAmounts(amounts[1], deal.computedAmount.currency2);
      }

      if ('currency2' in deal.originalAmount && 'currency1' in deal.computedAmount) {
        amounts[2] = addAmounts(amounts[2], deal.computedAmount.currency1);
        amounts[3] = addAmounts(amounts[3], deal.originalAmount.currency2);
      }

      return amounts;
    },
    [0, 0, 0, 0],
  );

export function TotalAmount() {
  const { sessionId, currency1, currency2 } = useParams();

  const currencyPair = useAppSelector((state) =>
    selectCurrencyPairDealsFromSessionIdVacation(state, sessionId, { currency1, currency2 }),
  );

  let amounts = [0, 0, 0, 0];

  if (currencyPair !== undefined) {
    amounts = getAmountsByCurrency(currencyPair.deals);
  }

  const [
    ccy1OriginalAmountSum,
    ccy2ComputedAmountSum,
    ccy1ComputedAmountSum,
    ccy2OriginalAmountSum,
  ] = amounts;

  const ccy1 = (ccy1OriginalAmountSum + ccy1ComputedAmountSum) || 0;
  const ccy2 = (ccy2OriginalAmountSum + ccy2ComputedAmountSum) || 0;
  const isMillion = ccy1 >= 1000000 || ccy2 >= 1000000;

  return (
    <div className="d-flex gap-4 justify-content-between">
      <div className="d-flex flex-column gap-8px">
        <span className="text-secondary" style={labelStyle}>
          <FormattedMessage id="totalAmount" />
        </span>
        <h4 className="text-nowrap" style={strongLabelStyle}>
          {formatNumeric(ccy1, isMillion)} {currency1} / {formatNumeric(ccy2, isMillion)} {currency2}
        </h4>
      </div>
      <div className="d-flex flex-column gap-4px justify-content-end">
        <span style={normalAmountStyle}>
          <strong style={strongAmoutStyle}>
            {formatNumeric(ccy1OriginalAmountSum, isMillion)} {currency1}
          </strong>{' '}
          <span className="text-secondary">/</span> {formatNumeric(ccy2ComputedAmountSum, isMillion)}{' '}
          {currency2}
        </span>
        <span style={normalAmountStyle}>
          {formatNumeric(ccy1ComputedAmountSum, isMillion)} {currency1}{' '}
          <span className="text-secondary">/</span>{' '}
          <strong style={strongAmoutStyle}>{formatNumeric(ccy2OriginalAmountSum, isMillion)} </strong>{' '}
          {currency2}
        </span>
      </div>
    </div>
  );
}
