import { ClosedVacationEventData, CurrencyPair, Vacation } from '@/features/vacation/vacationModel';
import { PatchCollection, Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { isDefined } from '@sgme/fp';
import { setLastVacation } from '@/features/vacation/vacationSlice';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { clearBlotter } from '@/features/blotter/blotterSlice';

type NewVacationPayload = {
  eventType: 'NEW_VACATION_RECEIVED';
  eventData: { vacation: Vacation };
};

type ClosedVacationPayload = {
  eventType: 'VACATION_CLOSED';
  eventData: ClosedVacationEventData;
};

export type DealsUpdatedPayload = {
  eventType: 'DEALS_UPDATED';
  eventData: {
    vacationId: string;
    currencyPair: CurrencyPair;
    status: string;
  };
};

export type DealsDeletePayload = {
  eventType: 'DEALS_DELETED';
  eventData: {
    vacationId: string;
    currencyPair: CurrencyPair;
    status: string;
  };
};

export type DealsSentPayload = {
  eventType: 'DEALS_SENT';
  eventData: {
    vacationId: string;
    currencyPair: CurrencyPair;
    status: string;
  };
};

export type DealsDiscardedPayload = {
  eventType: 'DEALS_DISCARDED';
  eventData: {
    vacationId: string;
    currencyPair: CurrencyPair;
    status: string;
  };
};

export type VacationNotificationPayload =
  | NewVacationPayload
  | ClosedVacationPayload
  | DealsUpdatedPayload
  | DealsDeletePayload
  | DealsDiscardedPayload
  | DealsSentPayload;

export const handleVacationEvent = (
  updateCachedData: (updateRecipe: Recipe<Vacation[]>) => PatchCollection,
  { eventType, eventData }: VacationNotificationPayload,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  switch (eventType) {
    case 'NEW_VACATION_RECEIVED': {
      updateCachedData((draft) => {
        draft.unshift(eventData.vacation);
      });
      dispatch(setLastVacation(eventData.vacation));
      break;
    }
    case 'VACATION_CLOSED': {
      updateCachedData((draft) => {
        const currentVacation = draft.find((vacation) => vacation.isCurrent);

        if (isDefined(currentVacation)) {
          currentVacation.isCurrent = false;
        }
        dispatch(clearBlotter());
      });
      break;
    }
    case 'DEALS_DELETED':
    case 'DEALS_SENT':
    case 'DEALS_DISCARDED':
    case 'DEALS_UPDATED': {
      updateCachedData((draft) => { 
        const currentVacation = draft.find(
          (vacation) => vacation.id === eventData.vacationId && vacation.isCurrent,
        );

        if (isDefined(currentVacation)) {
          currentVacation.currencyPairs = currentVacation.currencyPairs.map((currencyPair) => {
            if (currencyPair.id === eventData.currencyPair.id) {
              return eventData.currencyPair;
            }

            return currencyPair;
          });
        }
      });
      break;
    }
  }
};
