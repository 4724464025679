import { CurrentTimeContextProvider } from '@/context/CurrentTimeContext';
import { ExpirationSessionGrowlProvider } from '@/context/ExpirationSessionGrowlContext';
import { IntlProvider } from '@/context/IntlContext';
import { Homepage } from '@/pages/Homepage';
import { OnUserInfo, requestAuthorization } from '@/widgets/widgets';
import { setupAgGridLicence } from '@sgme/aggrid';
import { isDefined } from '@sgme/fp';
import { ServiceLoader } from '@sgme/ui';
import { SGWTConnectError } from '@sgwt/connect-core';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorBoundary } from './context/ErrorBoundary';
import App from './layout/App';
import Booking from './layout/Booking';
import ErrorLayout from './layout/ErrorLayout';
import { worker } from './mocks/browser';
import SessionPage from './pages/SessionPage';
import { store } from './state/store';
import { initSgBootstrap } from './utils/theme';
import ErrorGrowl from './components/shared/ErrorGrowl';
import { SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import { getConfig } from './config/config';
/**
 * @TODO when sgcomponent integration with front-core was done
 * can use front-core than this custom implementation
 */
/////////////////////////////
import { defineCustomElements } from '@sg-bootstrap/components/loader';
import { setUser } from '@/features/user/userSlice';

if (import.meta.env.VITE_USE_MOCK === 'true') {
  worker.start();
}

defineCustomElements(window);
/////////////////////////////

setupAgGridLicence();

initSgBootstrap();

function renderApp() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorLayout />,
      children: [
        {
          path: '/',
          element: <Homepage />,
        },
      ],
    },
    {
      path: '/sessions',
      element: <Booking />,
      errorElement: <ErrorLayout />,
      children: [
        {
          path: '/sessions/:sessionId',
          element: <SessionPage />,
        },
        {
          path: '/sessions/:sessionId/:currency1/:currency2',
          element: <SessionPage />,
        },
      ],
    },
  ]);

  const rootElement = document.getElementById('root')!;
  const root = createRoot(rootElement);
  const {
    matomo: { siteid, url },
  } = getConfig();

  root.render(
    <StrictMode>
      <Provider store={store}>
        <IntlProvider>
          <ErrorBoundary>
            <SgwtWidgetContextProvider infrastructure="azure">
              <SgwtWebAnalytics
                siteId={siteid}
                baseUrl={url}
                debug={getConfig().sgwt_env === 'homologation'} // To show debug on homolo env
              />
              <CurrentTimeContextProvider>
                <ExpirationSessionGrowlProvider>
                  <ServiceLoader>
                    <ErrorGrowl />
                    <RouterProvider router={router} />
                  </ServiceLoader>
                </ExpirationSessionGrowlProvider>
              </CurrentTimeContextProvider>
            </SgwtWidgetContextProvider>
          </ErrorBoundary>
        </IntlProvider>
      </Provider>
    </StrictMode>,
  );
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

requestAuthorization(renderApp, renderError, {
  onUserInfo: (userInfo) => {
    if (isDefined(userInfo)) {
      checkIAMRights(userInfo);
    }
  },
});

function checkIAMRights(userInfo: OnUserInfo) {
  const lmafxPermission = userInfo.user_authorization.find(
    (authorization) => authorization.resource === 'api.sgme-lmaforex',
  );

  if (lmafxPermission) {
    const permissionType = lmafxPermission.permissions[0].name;

    store.dispatch(
      setUser({
        sesameId: userInfo.sesame_id,
        permission: permissionType,
      }),
    );
  } else {
    window.location.replace(`${window.sgmeConfiguration.redirect_uri}`);
  }
}
