import { FC } from 'react';
import './SentDealsSelectionButton.css';
import { BookingStatus } from '@/features/vacation/vacationModel';

interface SentDealsSelectionActionButtonProps {
  selectAllSentDeals: (status: BookingStatus[]) => void;
  selectButtonLabel: string;
  isActive?: boolean;
}

const SentDealsSelectionActionButton: FC<SentDealsSelectionActionButtonProps> = ({
  selectAllSentDeals,
  selectButtonLabel,
  isActive,
}) => (
  <button
    data-e2e={'sent-deals-selection-btn'}
    className={`btn ${
      isActive ? 'btn-primary-alt ' : 'btn-outline-success'
    } py-1 px-3 rounded-pill icon-sent-color-hover${isActive ? '-active' : ''}`}
    onClick={() => selectAllSentDeals(['SENT'])}
  >
    <i role="img" className="icon-sm icon text-success px-1">
      {'check_circle'}
    </i>
    {selectButtonLabel}
  </button>
);

export default SentDealsSelectionActionButton;
