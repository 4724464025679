import './FormRates/FormRates.css';
import type { BidAsk, BlotterUrlParameters, Product } from '@/features/vacation/vacationModel';
import { type PropsWithChildren, useRef, useState } from 'react';

import {
  EditInput,
  getUpdatedInputsAll,
  InputsKey,
  isBlotterReadOnly,
  updateInput,
} from '@/features/blotter/blotterSlice';
import { useParams } from 'react-router-dom';
import { type ConvertPayload, DealsPayload, useConvertDealsMutation } from '@/api/deals.api';
import { DealToConvert } from '@/components/booking/Blotter/RatesColumnsHeader';
import { Placement, PopoverGlobalUpdate } from '@/components/booking/FormRates/PopoverGlobalUpdate';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHook';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { getUpdatedInput } from '@/utils/updateUnput';

type FormRatesProps = {
  buttonClasses: string;
  popoverPlacement: Placement;
  dealsToConvert: DealToConvert[];
  isSwapPointColumn?: boolean;
};

export const FormRates = ({
  children,
  buttonClasses,
  popoverPlacement,
  dealsToConvert,
  isSwapPointColumn = false,
}: PropsWithChildren<FormRatesProps>) => {
  const [show, setShow] = useState(false);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const buttonRef = useRef(null);
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);

  const dispatch = useAppDispatch();
  const { sessionId: vacationId, currency1, currency2 } = useParams() as BlotterUrlParameters;

  const updatedInputsAll = useAppSelector((state) => getUpdatedInputsAll(state));
  const [convertDeals, _result] = useConvertDealsMutation();

  function applyAllRates({ bid, ask }: BidAsk, date: string) {
    const convertMetaDataPayload = { vacationId, currency1, currency2 };

    const dealToConvert = !isSwapPointColumn
      ? dealsToConvert
      : dealsToConvert.filter((deal) => deal.valueDate!.proposedDate === date);

    const preparedDealsPayload = prepareDealsPayload(
      dealToConvert,
      { bid, ask },
      isSwapPointColumn,
    );

    const inputsChanged = isSwapPointColumn
      ? (['swapPoint.bid', 'swapPoint.ask'] as InputsKey[])
      : (['spotRate.bid', 'spotRate.ask'] as InputsKey[]);

    const payload: ConvertPayload = {
      deals: preparedDealsPayload,
      inputsChanged,
      ...convertMetaDataPayload,
    };

    sgwtWebAnalytics?.trackEvent(
      'Bulk Convert',
      'User actions',
      isSwapPointColumn ? 'Swap points' : 'Spot rate',
    );
    convertDeals(payload);
  }

  function prepareDealsPayload(
    dealToConvert: DealToConvert[],
    { bid, ask }: BidAsk,
    isSwapPointColumn: boolean,
  ): DealsPayload[] {
    return dealToConvert.map((deal) => {
      const updatedInput = getUpdatedInput(updatedInputsAll, deal.dealId);

      const dealSwapPoint = {
        bid: (updatedInput?.['swapPoint.bid'] ?? deal.swapPoint?.bid) as number,
        ask: (updatedInput?.['swapPoint.ask'] ?? deal.swapPoint?.ask) as number,
      };
      const dealSpotRate = {
        bid: (updatedInput?.['spotRate.bid'] ?? deal.spotRate?.bid) as number,
        ask: (updatedInput?.['spotRate.ask'] ?? deal.spotRate?.ask) as number,
      };

      const product = (updatedInput?.['product'] ?? deal.product) as Product;

      const swapPoint = isSwapPointColumn ? { bid, ask } : dealSwapPoint;
      const spotRate = !isSwapPointColumn ? { bid, ask } : dealSpotRate;

      return {
        dealId: deal.dealId,
        product,
        swapPoint,
        spotRate,
      };
    });
  }

  const possibleDates = isSwapPointColumn ? getPossibleDates(dealsToConvert) : [];

  return (
    <>
      <button
        data-e2e={`${isSwapPointColumn ? 'swap' : 'spot'}-${
          popoverPlacement === 'bottom' ? '' : 'footer-'
        }apply-global-rates-btn`}
        ref={buttonRef}
        className={buttonClasses}
        disabled={blotterReadOnly}
        onClick={() => setShow(!show)}
      >
        {children}
      </button>
      <PopoverGlobalUpdate
        buttonRef={buttonRef}
        placement={popoverPlacement}
        show={show}
        onHide={() => setShow(false)}
        onClick={applyAllRates}
        dateOptions={possibleDates ?? []}
      />
    </>
  );
};

function getPossibleDates(dealsToConvert: DealToConvert[]) {
  return dealsToConvert.reduce((dates: string[], deal) => {
    const dealDate = deal.valueDate?.proposedDate ?? '';
    const isDatesExists = dates.some((date) => date === dealDate);

    if (!isDatesExists) {
      dates.push(dealDate);
    }

    return dates;
  }, []);
}
