import { selectCurrencyPairsFromSessionIdVacation } from '@/features/vacation/vacationSelectors';
import { useAppSelector } from '@/hooks/reduxHook';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { PanelItem } from './PanelItem';
import Spinner from '@/components/shared/Spinner';
import { isDefined } from '@sgme/fp';
import { useState } from 'react';
import CollapsableButton from './CollapsableButton';

function Panel() {
  const [isBlotterOpen, setIsBlotterOpen] = useState(true);

  const { sessionId } = useParams();

  const currencyPairs = useAppSelector((state) =>
    selectCurrencyPairsFromSessionIdVacation(state, sessionId),
  );

  return (
    <aside style={{ width: !isBlotterOpen ? '3.7rem' : '' }} className="border-end">
      <header className={`d-flex ${isBlotterOpen ? 'p-16px' : 'py-16px'} sticky gap-5`}>
        {isBlotterOpen && (
          <Link to="/" role="button">
            <button role="button" className="btn btn-md btn-outline-primary mt-2">
              <FormattedMessage id="back.session.view" />
            </button>
          </Link>
        )}

        <CollapsableButton isBlotterOpen={isBlotterOpen} setIsBlotterOpen={setIsBlotterOpen} />
      </header>

      {isBlotterOpen && (
        <section>
          <div className="list-group">
            {currencyPairs.length > 0 && isDefined(sessionId) ? (
              currencyPairs.map(
                (
                  { currency1, currency2, dealNumbers, xoneDealNumbers, dealOwner, status },
                  index,
                ) => (
                  <PanelItem
                    sessionId={sessionId!} //Need for link to With router we have sessionId
                    currency1={currency1}
                    currency2={currency2}
                    key={`${sessionId}-${index}`}
                    numberOfAlreadyBookedDeals={xoneDealNumbers}
                    numberOfDeals={dealNumbers}
                    status={status}
                    dealOwner={dealOwner}
                  />
                ),
              )
            ) : (
              <Spinner />
            )}
          </div>
        </section>
      )}
    </aside>
  );
}

export default Panel;
