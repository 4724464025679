import { Blotter } from '@/components/booking/Blotter';
import { BookingKpi } from '@/components/booking/BookingKpi';
import DealsBulkActionsFooter from '@/components/booking/DealsBulkActionFooter';
import {
  selectCurrencyPairDealsFromSessionIdVacation,
  selectVacationById,
} from '@/features/vacation/vacationSelectors';
import { useAppSelector } from '@/hooks/reduxHook';
import { isDefined } from '@sgme/fp';
import { useParams } from 'react-router-dom';
import { getPermission } from '@/features/user/userSlice';

function SessionPage() {
  const { sessionId, currency1, currency2 } = useParams();

  const vacation = useAppSelector((state) => selectVacationById(state, sessionId));
  const currencyPairWithDeals = useAppSelector((state) =>
    selectCurrencyPairDealsFromSessionIdVacation(state, sessionId, { currency1, currency2 }),
  );

  const hasUserWritePermission =
    useAppSelector((state) => getPermission(state)) === 'LMAFX_READWRITE';

  return (
    <>
      {isDefined(vacation) &&
        isDefined(currencyPairWithDeals) &&
        isDefined(currency1) &&
        isDefined(currency2) && (
          <div className="d-flex flex-column h-100">
            <div className="p-4">
              <BookingKpi
                currentSessionTime={vacation.sessionTime}
                currency1={currency1}
                currency2={currency2}
                dealNumbers={currencyPairWithDeals.dealNumbers}
                sendDealNumbers={currencyPairWithDeals.xoneDealNumbers}
              />
            </div>
            <div
              className={`d-flex flex-column flex-grow-1 px-24px ${
                !(hasUserWritePermission && vacation.isCurrent) ? 'pb-24px' : ''
              }`}
            >
              <Blotter
                // this is done to make sure that the underlying agGrid is destroyed and recreated whenever we change
                // the currency pair as DealsBulkActionsFooter relies on some specific ag-rgid livecycle events
                // to stay in sync with agGrid state
                key={`${currency1}-${currency2}`}
                deals={currencyPairWithDeals.deals}
                currency1={currency1}
                currency2={currency2}
                vacationId={vacation.id}
                currencyPairId={currencyPairWithDeals.id}
                dealOwner={currencyPairWithDeals.dealOwner}
                isCurrent={vacation.isCurrent}
              />
              {hasUserWritePermission && vacation.isCurrent ? (
                <div data-e2e="footer-bulk-action">
                  <DealsBulkActionsFooter
                    currency1={currency1}
                    currency2={currency2}
                    vacationId={vacation.id}
                    currencyPairId={currencyPairWithDeals.id}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
    </>
  );
}

export default SessionPage;
