import { ChangeEvent, FormEvent, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { BidAsk, BlotterUrlParameters } from '@/features/vacation/vacationModel';
import { useIntl } from 'react-intl';
import { Overlay, Popover, PopoverBody } from 'react-bootstrap';
import { DateTime as LuxonDatetime } from 'luxon';
import { useAppSelector } from '@/hooks/reduxHook';
import { getVacationFirstSpotRate } from '@/features/vacation/vacationSelectors';
import { useParams } from 'react-router-dom';
import { isDefined, isNotDefined } from '@sgme/fp';

export type Placement = 'top' | 'bottom';

type PopoverGlobalUpdateProps = {
  buttonRef: MutableRefObject<null>;
  placement: Placement;
  show: boolean;
  onHide: () => void;
  onClick: (bidAsk: BidAsk, date: string) => void;
  dateOptions: string[];
};

export function PopoverGlobalUpdate({
  buttonRef,
  placement,
  show,
  onHide,
  onClick,
  dateOptions,
}: PopoverGlobalUpdateProps) {
  const { formatMessage } = useIntl();

  const isEmptyDateArray = dateOptions.length <= 0;

  const [date, setDate] = useState<string>(!isEmptyDateArray ? dateOptions[0] : '');
  const [bid, setBid] = useState<number | undefined>();
  const [ask, setAsk] = useState<number | undefined>();

  const { sessionId: vacationId, currency1, currency2 } = useParams() as BlotterUrlParameters;
  const prefillBidAsk = useAppSelector(state => getVacationFirstSpotRate(state, vacationId, { currency1, currency2 }));

  useEffect(() => {
    if (isEmptyDateArray) {
      // Je suis sur un spot car pas de date

      if (isDefined(prefillBidAsk?.bid) && isNotDefined(bid)) {
        setBid(prefillBidAsk.bid)
      }

      if (isDefined(prefillBidAsk?.ask) && isNotDefined(ask)) {
        setAsk(prefillBidAsk.ask)
      }
    }
    // Uniquement au first render je pre rempli les valeurs
  }, [])


  const hasBidWrongValue = bid === undefined || bid === 0;
  const hasAskWrongValue = ask === undefined || ask === 0;
  const isButtonDisabled = hasBidWrongValue || hasAskWrongValue;

  const handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setDate(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (bid !== undefined && ask !== undefined) {
      onClick({ bid, ask }, date);
    }
  };

  return (
    <Overlay
      show={show}
      target={buttonRef.current}
      placement={placement}
      onHide={onHide}
      rootClose={true}
    >
      <Popover id="popover-contained">
        <PopoverBody>
          <form className="d-flex flex-column" onSubmit={handleSubmit}>
            {!isEmptyDateArray && (
              <div className="mb-2 mt-1">
                <div className="mb-1 fw-medium text-secondary">
                  {formatMessage({ id: `blotter.columnsHeader.rates.applyAllPopover.select` })}
                </div>
                <select
                  className="form-select"
                  onChange={handleChangeSelect}
                  data-e2e={'apply-global-rates-date-select'}
                >
                  {dateOptions &&
                    dateOptions.map((option) => (
                      <option value={option} key={option}>
                        {LuxonDatetime.fromISO(option).setLocale('en-US').toFormat('dd LLL yyyy')}
                      </option>
                    ))}
                </select>
              </div>
            )}
            <div className="d-flex align-items-end">
              <div className="pe-2">
                <div className="fw-medium text-secondary">
                  {formatMessage({ id: `blotter.columnsHeader.rates.applyAllPopover.bid` })}
                </div>
                <InputGlobalUpdate value={bid} onChange={setBid} dataE2e="bid" />
              </div>
              <div className="pe-2">
                <div className="fw-medium text-secondary">
                  {formatMessage({ id: `blotter.columnsHeader.rates.applyAllPopover.ask` })}
                </div>
                <InputGlobalUpdate value={ask} onChange={setAsk} dataE2e="ask" />
              </div>
              <div>
                <button
                  className="btn btn-default fw-semibold"
                  type="submit"
                  disabled={isButtonDisabled}
                  data-e2e={'apply-global-rates-apply-button'}
                >
                  {formatMessage({ id: `blotter.columnsHeader.rates.applyAllPopover.apply` })}
                </button>
              </div>
            </div>
          </form>
        </PopoverBody>
      </Popover>
    </Overlay>
  );
}

type InputGlobalUpdateProps = {
  value?: number;
  dataE2e: string;
  onChange: (value: number) => void;
};

// eslint-disable-next-line react/display-name
const InputGlobalUpdate = ({ onChange, value, dataE2e }: InputGlobalUpdateProps) => {
  const { formatMessage } = useIntl();

  const [valueState, setValueState] = useState<string>(`${value}`);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.valueAsNumber;
      const valueAsString = event.target.value;

      if (!isNaN(value)) {
        onChange(value);
      }

      setValueState(valueAsString);
    },
    [onChange],
  );

  return (
    <input
      className="bidask-input form-control text-end"
      type="number"
      placeholder={formatMessage({
        id: `blotter.columnsHeader.rates.applyAllPopover.inputPlaceholder`,
      })}
      onChange={handleChange}
      value={valueState}
      data-e2e={`apply-global-rates-${dataE2e}-input`}
      step="0.01"
    />
  );
};
