import { SGWTConnectError } from '@sgwt/connect-core';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import { SgwtConnectHTMLElement } from '@/types/sgwt-widgets';

export const BUS_TOPIC_GLOBALLANGUAGE = 'global.language';
const BUS_TOPIC_SGCONNECT_USERCONNECTION = 'sg-connect.user-connection';
const BUS_TOPIC_SGCONNECT_USERINFO = 'sg-connect.user-info';
const TOPIC_SGCONNECT_READY = 'sgwt-connect--ready';

type BusEventCallback<T> = (payload: T | undefined) => void;
type SubscriptionHandle = any;

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined | null;

  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;

  unsubscribe(handle: SubscriptionHandle): void;
}

export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus ? widgetConfiguration.bus : null;
}

interface UserConnection {
  connected: boolean;
  claims: Record<string, string>;
  mail: string;
}

export function getUserConnection(): UserConnection | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<UserConnection>(BUS_TOPIC_SGCONNECT_USERCONNECTION);
  }
  return undefined;
}

type Subscribers = {
  onUserInfo: (userInfo: OnUserInfo | undefined) => void;
};

export type OnUserInfo = {
  sesame_id: string;
  user_authorization: Authorization[];
};

type Authorization = {
  resource: string;
  permissions: { name: string }[];
};

export function requestAuthorization(
  onAuthorizedSuccess: () => void,
  onAuthorizationError: (authorizationError: SGWTConnectError) => void,
  subscribers: Subscribers,
) {
  if (sgwtConnect.isAuthorized()) {
    setupSgwtConnectWidget();

    if ((window as any).SGWTWidgetConfiguration) {
      subscribeToWidgetBus(subscribers);
    }

    onAuthorizedSuccess();
  } else {
    const authorizationError = sgwtConnect.getAuthorizationError();

    if (authorizationError) {
      onAuthorizationError(authorizationError);
    } else {
      sgwtConnect.requestAuthorization();
    }
  }
}

function subscribeToWidgetBus({ onUserInfo }: Subscribers) {
  const bus = getWidgetBus();

  if (bus && onUserInfo) {
    bus.subscribe(BUS_TOPIC_SGCONNECT_USERINFO, onUserInfo);
  }
}

export function setupSgwtConnectWidget() {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');

  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener(TOPIC_SGCONNECT_READY, handleSgwtConnectReady);
      };

      widget.addEventListener(TOPIC_SGCONNECT_READY, handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}
