import { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export interface AppConfig {
  sgwt_env: 'homologation' | 'production';
  env: 'devci' | 'uatrc' | 'prod';
  apiUrl: string;
  streaming: {
    hubUrl: string;
  };
  redirect_uri: string;
  sgconnect: ISGWTConnectConfiguration;

  matomo: {
    url: string;
    siteid: string;
  };
}

export function getConfig(): AppConfig {
  return window.sgmeConfiguration;
}
