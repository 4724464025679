import { Header } from '@/layout/Header';
import './ErrorLayout.css';
import ErrorPage from '@/pages/ErrorPage';
import { Footer } from './Footer';

function ErrorLayout() {
  return (
    <div className="lma-error">
      <Header />
      <main className="d-flex align-items-center justify-content-center">
        <div style={{ minWidth: '100vh' }}>
          <ErrorPage />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default ErrorLayout;
