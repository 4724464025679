import { makeColumnDef } from '@/components/booking/Blotter/columnDefinition';
import {
  getSelectedDealStatus,
  getUpdatedInputsAll,
  getUpdatedSelectedRows,
  setSelectedRows,
  setSelectedStatusDeal,
} from '@/features/blotter/blotterSlice';
import { Deal } from '@/features/vacation/vacationModel';
import { useDebounce } from '@/hooks/debounce';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHook';
import {
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  SelectionChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import { AgGridReact } from 'ag-grid-react';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import './Blotter.css';
import { getPermission } from '@/features/user/userSlice';

export type BlotterProps = {
  deals: Deal[];
  vacationId: string;
  currency1: string;
  currency2: string;
  currencyPairId: string;
  isCurrent: boolean;
  dealOwner: string;
};

export const Blotter = memo(
  ({ deals, vacationId, currencyPairId, currency1, currency2, isCurrent = true }: BlotterProps) => {
    const appDispatch = useAppDispatch();
    const updatedInputsAll = useAppSelector(getUpdatedInputsAll);

    const selectedStatus = useAppSelector(getSelectedDealStatus);
    const selectedDeals = useAppSelector(getUpdatedSelectedRows);
    const convertedDealsId = useAppSelector((state) => state.blotter.convertedDealsId);
    const refreshedDealsId = useAppSelector((state) => state.blotter.refreshedDealsId);

    const apiRef = useRef<GridApi>();

    const hasUserWritePermission =
      useAppSelector((state) => getPermission(state)) === 'LMAFX_READWRITE';

    /**
     * Juste format with Intl number 1234567.89 => 1,234,567.89
     * @TODO maybe handle Fr/En number format depending on user's settings
     * @param {value: number}
     * @returns {string}
     */
    const { formatNumber, formatMessage } = useIntl();

    const convertMetaDataPayload = useMemo(
      () => ({ vacationId, currency1, currency2, currencyPairId }),
      [currency1, currency2, currencyPairId, vacationId],
    );

    const colDefs = makeColumnDef({
      isCurrent: isCurrent && hasUserWritePermission,
      formatMessage,
      formatNumber,
      currency1,
      currency2,
      convertedDealsId,
      refreshedDealsId,
      convertMetaDataPayload,
      updatedInputsAll,
    });

    /**
     * [SYNCRHONIZATION - State & Aggrid row data]
     * Based on the selectedDeals in the store
     * if handle it and update aggrid state
     * Syncrhonization between selectedDeals in the state && aggrid NodesSelected
     */
    useEffect(() => {
      const nodesToSelect: IRowNode[] = [];
      const ids = selectedDeals.map((deal) => deal.id);

      if (ids.length > 0) {
        // If selectedDeals in the state
        apiRef.current?.forEachNode((node: IRowNode<Deal>) => {
          if (node.data && ids.includes(node.data.id)) {
            // If we have a matching deal in row data of aggrid
            // push to an array
            nodesToSelect.push(node);
          }
          // each selectedDeals present in aggrid's rowdata a select (trigger checked on each)
          apiRef.current?.setNodesSelected({ nodes: nodesToSelect, newValue: true });
        });
      }

      if (apiRef.current?.getSelectedNodes().length !== ids.length) {
        // If selectedDeals are empty, and we have no aggrid selected row, that means we deselect all
        apiRef.current?.deselectAll();
      }
    }, [selectedDeals]);

    /**
     * Call sizeColumnsToFit with a debounce time (default 300ms)
     * to resize the grid to fit perfectly with available space
     * https://www.ag-grid.com/javascript-data-grid/column-sizing/#size-columns-to-fit
     */
    const sizeToFit = useDebounce(() => {
      apiRef?.current?.sizeColumnsToFit();
    });

    /**
     * When event `gridReady` is sent we can access ag grid's api
     * So 300ms after grid is ready, we adjust the size
     */
    const onGridReady = useCallback(
      (event: GridReadyEvent) => {
        apiRef.current = event.api;
        sizeToFit();
      },
      [sizeToFit],
    );

    /**
     * UseEffect on window's event `resize`
     * So when the user resizes their windows
     * we call `sizeToFit` function that debounce (300ms) the ag grid resizing
     *
     * Cleanup is here to avoid error when unmount component
     */
    useEffect(() => {
      window.addEventListener('resize', sizeToFit);
      return function cleanup() {
        window.removeEventListener('resize', sizeToFit);
      };
    }, [sizeToFit]);

    // whenever isCurrent changes to false, we want to deselect all rows
    useEffect(() => {
      if (!isCurrent) {
        apiRef.current?.deselectAll();
      }
    }, [isCurrent]);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = {
      sortable: true,
      resizable: false,
      lockPosition: true,
    };

    /**
     * Callback to determine if a row is selectable or not
     * Base on the state selectedStatus, to track each update
     */
    const isRowSelectable = useCallback(
      (rowNode: IRowNode<Deal>) => {
        const rowStatus =
          rowNode?.data?.status === 'INPROGRESS' ? 'TODO' : (rowNode?.data?.status ?? '');

        if (['UNSUCCESSFUL', 'DELETED'].includes(rowStatus)) {
          return false;
        }

        return selectedStatus ? rowStatus === selectedStatus : true;
      },
      [selectedStatus],
    );

    /**
     * When we check some rows, this callback is trigger
     * Depending of the selectedNodes empty or not, we dispatch some action
     * 🚧 it's not the same as the useEffect on selectedDeals
     * It's like a "useEffect" base on AgGrid first.
     */
    const onSelectionChanged = useCallback(
      (event: SelectionChangedEvent<Deal>) => {
        const selectedNodes = event.api.getSelectedNodes();

        if (selectedNodes.length === 0) {
          // If the event and no selectedNodes in aggrid, that means we deselect all so dispatch reset actions
          appDispatch(setSelectedRows({ selectedRows: [] }));
          appDispatch(setSelectedStatusDeal({ dealStatus: null }));
          return;
        }

        // Otherwise, we have selected 1 or more deals
        // Get the status and dispatch this selectedStatus, and associated rows
        const dealSelectedStatus =
          selectedNodes[0].data?.status === 'INPROGRESS' ? 'TODO' : selectedNodes[0].data?.status;
        appDispatch(setSelectedStatusDeal({ dealStatus: dealSelectedStatus! }));

        const dealsId = selectedNodes.map((deal) => deal.data?.id);
        const selectedDeals = deals.filter((deal) => dealsId.includes(deal.id));
        appDispatch(setSelectedRows({ selectedRows: selectedDeals }));
      },
      [appDispatch, deals],
    );

    return (
      <div data-e2e="blotter-aggrid" className="ag-theme-sg-bootstrap h-100">
        <AgGridReact<Deal>
          isRowSelectable={isRowSelectable}
          onSelectionChanged={onSelectionChanged}
          rowData={deals}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          suppressRowClickSelection // need to click on the checkbox to select it
          suppressCellFocus // remove blue ring in chrome
          rowSelection="multiple"
          onGridReady={onGridReady}
          rowHeight={48} // in pixel
          getRowId={(params: GetRowIdParams) => params.data.id} // this assigns dealId as rowId
        />
      </div>
    );
  },
  arePropsEqual,
);

import isDeepEqual from 'fast-deep-equal';
function arePropsEqual(oldProps: BlotterProps, newProps: BlotterProps) {
  return (
    oldProps.isCurrent === newProps.isCurrent &&
    oldProps.deals.every((oldDeal, index) => {
      const newDeal = newProps.deals[index];
      return isDeepEqual(oldDeal, newDeal);
    })
  );
}
