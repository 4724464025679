import { retry } from '@/core/signalR/retry';
import { HubConnection, HubConnectionBuilder, LogLevel, RetryContext } from '@microsoft/signalr';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import { getConfig } from '@/config/config';
import { logger } from '@/core/logger';
import { streamingStateChanged } from '@/features/streaming/streamingSlice';
import { AppDispatch } from '@/state/store';

const MAX_RETRIES = 5;
const DELAY_MS = 1_000;
const RECONNECT_DELAY = 500;

const {
  streaming: { hubUrl },
} = getConfig();
function getAuthorizationToken(): string | undefined {
  const authorizationHeader = sgwtConnect.getAuthorizationHeader();
  if (authorizationHeader === null) {
    return undefined;
  }
  return authorizationHeader.replace('Bearer ', '');
}

function createSignalrConnection(): HubConnection {
  return new HubConnectionBuilder()
    .withUrl(hubUrl, {
      accessTokenFactory: () => getAuthorizationToken() ?? '',
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
        const {
          previousRetryCount,
          elapsedMilliseconds,
          retryReason: { stack: retryReasonStack },
        } = retryContext;
        logger.logWarning(
          'Attempt to auto reconnect. Retry count: {previousRetryCount_n}. Time spend reconnecting in ms: {elapsedMilliseconds_n}. Reason: {retryReasonStack_s}',
          previousRetryCount,
          elapsedMilliseconds,
          JSON.stringify(retryReasonStack),
        );

        if (retryContext.elapsedMilliseconds < 60 * 1000) {
          return RECONNECT_DELAY;
        } else {
          return RECONNECT_DELAY * 10;
        }
      },
    })
    .build();
}

export const signalrConnection = createSignalrConnection();

export const startConnection = (dispatch: AppDispatch) =>
  retry(() => signalrConnection.start(), { maxRetries: MAX_RETRIES, delayMs: DELAY_MS }).then(
    () => {
      const connectionId = signalrConnection.connectionId!;
      logger.logInformation('Established signalR connection for {connectionId}', connectionId);
      dispatch(streamingStateChanged({ state: 'connected', connectionId }));
    },
    () => {
      logger.logError(
        'Fail to establish signalR connection for {connectionId}',
        signalrConnection.connectionId,
      );
    },
  );
