import './App.css';
import { Header } from './Header';
import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { useGetVacationsQuery } from '@/api/vacation.api';
import { useNotifications } from '@/hooks/useNotifications';

function App() {
  useGetVacationsQuery();
  useNotifications();

  return (
    <div className="app">
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default App;
