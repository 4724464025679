import { Indicators } from '@/components/homepage/Indicators';
import Sessions from '@/components/homepage/Sessions';

export function Homepage() {
  return (
    <>
      <Indicators />
      <Sessions />
    </>
  );
}
