type ColleapsableButtonProps = {
  isBlotterOpen: boolean;
  setIsBlotterOpen: (isBlotterOpen: boolean) => void;
};

function CollapsableButton(props: ColleapsableButtonProps) {
  const { isBlotterOpen, setIsBlotterOpen } = props;

  return (
    <button
      className="btn btn-md btn-flat-secondary mt-1 ms-1"
      onClick={() => {
        window.dispatchEvent(new Event('resize'));
        setIsBlotterOpen(!isBlotterOpen);
      }}
    >
      <em className="icon" data-e2e="collapsable-icon">
        {!isBlotterOpen ? 'last_page' : 'first_page'}
      </em>
    </button>
  );
}

export default CollapsableButton;
