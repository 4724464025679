import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { PossibleSessionTime } from '@/features/vacation/vacationModel';

type ExpirationSessionGrowlContextValues = {
  lastGrowlDismissedOrExpiredSessionTime: PossibleSessionTime | undefined;
  setLastGrowlDismissedOrExpiredSessionTime: Dispatch<
    SetStateAction<PossibleSessionTime | undefined>
  >;
};

export const ExpirationSessionGrowlContext = createContext<ExpirationSessionGrowlContextValues>({
  lastGrowlDismissedOrExpiredSessionTime: undefined,
  setLastGrowlDismissedOrExpiredSessionTime: () => {},
});

export const ExpirationSessionGrowlProvider = ({ children }: PropsWithChildren) => {
  const [lastGrowlDismissedOrExpiredSessionTime, setLastGrowlDismissedOrExpiredSessionTime] =
    useState<PossibleSessionTime | undefined>(undefined);

  const values = {
    lastGrowlDismissedOrExpiredSessionTime,
    setLastGrowlDismissedOrExpiredSessionTime,
  };

  return (
    <ExpirationSessionGrowlContext.Provider value={values}>
      {children}
    </ExpirationSessionGrowlContext.Provider>
  );
};
