import { DateTime as LuxonDatetime } from 'luxon';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { CurrentTimeContext } from '@/context/CurrentTimeContext';

export type TimeKpiProps = {
  labelId: string;
  local?: 'es-US' | 'fr';
  overrideDate?: LuxonDatetime;
};

const labelStyle: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0em',
  // textAlign: 'center',
};
export function Clock({ labelId }: TimeKpiProps) {
  const currentTime = useContext(CurrentTimeContext);
  return (
    <div
      data-e2e={`clock-${labelId}`}
      className="d-flex flex-column gap-2"
      style={{ minWidth: '100px' }}
    >
      <span className="text-secondary text-nowrap" style={labelStyle}>
        <FormattedMessage id={labelId} />
      </span>
      <h4 className="text-nowrap">{currentTime}</h4>
    </div>
  );
}
