import { Clock } from '@/components/shared/Clock';
import { DateKpi } from '@/components/shared/DateKpi';
import { SessionRemainingTimeCountDown } from '@/components/shared/SessionRemainingTimeCountDown';

export function Indicators() {
  return (
    <div className="d-flex align-items-end pt-3 pb-4 border-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-3 mb-lg-0">
            <h1 className="display-3 m-0 ">FX Sessions</h1>
          </div>
          <div
            className="d-flex col-lg-4 d-lg-flex gap-3 align-items-center justify-content-end"
            style={{ maxWidth: '466px' }}
          >
            <DateKpi />
            <Clock labelId="currentTime" />
            <SessionRemainingTimeCountDown />
          </div>
        </div>
      </div>
    </div>
  );
}
