import { logger } from '@/core/logger';
import { addErrorToShow } from '@/features/errors/errorsSlice';
import { isAnyOf, isRejected } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

const isFailureReasonsAction = isAnyOf(addErrorToShow);
/**
 * Log an error !
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejected(action)) {
    // If action is a rejected action from rtk we redirect to the error page
    // https://redux-toolkit.js.org/api/matching-utilities#isrejected
    logger.logError('Catch rtkQuery error', action);
    window.location.href = '/error';
  }

  if (isFailureReasonsAction(action)) {
    logger.logError(
      `[${action.payload.dealsAction}] Failed on deals action`,
      action.payload.stateBackendErrors,
    );
  }

  return next(action);
};
