import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHook';
import { selectLastVacation } from '@/features/vacation/vacationSelectors';
import { isDefined } from '@sgme/fp';
import { PossibleSessionTime, Vacation } from '@/features/vacation/vacationModel';
import { useIntl } from 'react-intl';
import { setLastVacation } from '@/features/vacation/vacationSlice';
import { useHref } from 'react-router-dom';
import {
  checkNotificationIsAvailable,
  getNotification,
  requestPermission,
} from './notificationServices';

export function useNotifications() {
  const lastVacation = useAppSelector(selectLastVacation);
  const appDispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const href = useHref('sessions');

  // Use to manage permission
  useEffect(() => {
    const asyncRequestPermission = async () => {
      await requestPermission();
    };

    if (checkNotificationIsAvailable()) {
      asyncRequestPermission();
    }
  }, []);

  // Use to prepare notification and emit it.
  useEffect(() => {
    const getNotificationBody = (value: PossibleSessionTime) =>
      formatMessage({ id: `notification.started` }, { sessionTime: value });

    const showNotification = (vacation: Vacation) => {
      const { id, sessionTime } = vacation;
      const options: NotificationOptions = {
        body: getNotificationBody(sessionTime),
        badge: './logo.svg',
        tag: `NEW_VACATION_RECEIVED-${id}`,
      };

      const notification = getNotification({ title: 'SG Markets LMAFOREX', options });

      notification.onclose = (event) => {
        event.preventDefault();
        appDispatch(setLastVacation(null));
      };
      notification.onclick = (event) => {
        event.preventDefault();
        window.focus();
        notification.close();
      };
      setTimeout(() => {
        // Reset state lastVacation
        notification.close();
      }, 10000);
    };

    if (isDefined(lastVacation)) {
      showNotification(lastVacation);
    }
  }, [lastVacation, formatMessage, appDispatch, href]);
}
