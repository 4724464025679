import { FC, ReactNode, useEffect } from 'react';

type GrowlProps = {
  onClose: () => void;
  title: string;
  bodyMessage: ReactNode;
  titleColor: 'danger' | 'warning';
  right?: number;
  top?: number;
  testId: string;
  autocloseGrowl?: () => void;
};
const Growl: FC<GrowlProps> = ({
  testId,
  right,
  top,
  onClose,
  title,
  bodyMessage,
  titleColor,
  autocloseGrowl,
}) => {
  useEffect(() => {
    if (autocloseGrowl) {
      setTimeout(() => autocloseGrowl(), 10000);
    }
  }, [autocloseGrowl]);

  return (
      <div
        className={`toast toast-${titleColor} fade show`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-e2e={testId}
        style={{
          position: 'absolute',
          zIndex: 1000,
          ...(right ? { right } : {}),
          ...(top ? { top } : {}),
        }}
      >
        <div className="toast-header d-flex ">
          <div className={'flex-grow-1'}>
            <strong>{title}</strong>
          </div>
          <button
            type="button"
            className="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            onClick={onClose}
          >
            <i className="icon">close</i>
          </button>
        </div>
        <div className="toast-body">{bodyMessage}</div>
      </div>
  );
};

export default Growl;
