import { DeletePayload, useDeleteDealMutation } from '@/api/deals.api';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { useAppSelector } from '@/hooks/reduxHook';
import { Modal } from 'react-bootstrap';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

interface DeleteButtonProps {
  deals: DeletePayload[];
  overrideClassName?: string;
  isFromBulkAction?: boolean;
}

export function DeleteButton({ deals, overrideClassName, isFromBulkAction }: DeleteButtonProps) {
  const [deleteDeal, { isLoading }] = useDeleteDealMutation();
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleClick: React.FormEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      event.preventDefault();
      sgwtWebAnalytics?.trackEvent(isFromBulkAction ? 'Bulk Delete' : 'Delete', 'User actions');
      deleteDeal(deals);
    },
    [deleteDeal, deals, sgwtWebAnalytics, isFromBulkAction],
  );

  return (
    <>
      {!isLoading ? (
        <button
          role="button"
          data-e2e={`delete-${deals.length !== 1 ? 'deals' : deals[0].dealId}`}
          className={`${overrideClassName ? overrideClassName : 'btn btn-discreet-danger'}`}
          onClick={handleShow}
          disabled={blotterReadOnly}
        >
          {<FormattedMessage id="blotter.delete" />}
        </button>
      ) : (
        <div data-e2e="spinner" className="spinner spinner-sm" />
      )}

      <Modal
        show={showModal}
        onHide={handleClose}
        contentClassName="border border-danger"
        data-e2e="delete-deal-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <div>
              <i role="img" className="icon text-danger mb-4">
                error_outline
              </i>
            </div>
            <span className="text-danger">
              <FormattedMessage
                id={'blotter.dealsBulkAction.delete.modal.title'}
                values={{ count: deals.length }}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id={'blotter.dealsBulkAction.delete.modal.body'}
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            onClick={handleClose}
            data-e2e="delete-deal-modal-cancel-button"
          >
            <FormattedMessage id={'blotter.dealsBulkAction.delete.modal.button.close'} />
          </button>
          <button
            className="btn btn-danger"
            onClick={handleClick}
            data-e2e="delete-deal-modal-confirm-button"
          >
            <FormattedMessage
              id={'blotter.dealsBulkAction.delete.modal.button.deleteDeal'}
              values={{ count: deals.length }}
            />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteButton;
