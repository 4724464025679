import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { isDefined, isNotDefined } from '@sgme/fp';
import Growl from '@/components/shared/Growl';
import { FC, useCallback, useContext, useEffect } from 'react';
import { CurrentTimeContext } from '@/context/CurrentTimeContext';
import { PossibleSessionTime, Vacation } from '@/features/vacation/vacationModel';
import { ExpirationSessionGrowlContext } from '@/context/ExpirationSessionGrowlContext';
import { useAppSelector } from '@/hooks/reduxHook';
import { selectCurrentVacation } from '@/features/vacation/vacationSelectors';

const FIFTEEN_MINUTES = 60 * 15;
const TWO_MINUTE = 2 * 60;
const FIVE_MINUTES = 5 * 60;

const labelStyle: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0em',
};

type SessionRemainingTimeCountDownProps = {
  growlRightPosition?: number;
  growlTopPosition?: number;
};

export const SessionRemainingTimeCountDown: FC<SessionRemainingTimeCountDownProps> = ({
  growlRightPosition,
  growlTopPosition,
}) => {
  const currentVacation = useAppSelector(selectCurrentVacation);

  return (
    <div
      data-e2e={`clock-timeRemaining`}
      className="d-flex flex-column gap-2"
      style={{ minWidth: '100px' }}
    >
      <span className="text-secondary text-nowrap" style={labelStyle}>
        <FormattedMessage id="timeRemaining" />
      </span>
      {isNotDefined(currentVacation) ? (
        <h4 className="text-nowrap">--:--:--</h4>
      ) : (
        <CountDown
          currentVacation={currentVacation}
          growlRightPosition={growlRightPosition}
          growlTopPosition={growlTopPosition}
        />
      )}
    </div>
  );
};

type CountDownProps = {
  currentVacation: Vacation;
  growlRightPosition?: number;
  growlTopPosition?: number;
};
export const CountDown: FC<CountDownProps> = ({
  currentVacation,
  growlRightPosition,
  growlTopPosition,
}) => {
  const currentTime = useContext(CurrentTimeContext);
  const lastSessionStartTime = currentVacation.sessionTime;
  const remainingTimeInSeconds = computeRemainingTime(currentTime, lastSessionStartTime);

  const { lastGrowlDismissedOrExpiredSessionTime, setLastGrowlDismissedOrExpiredSessionTime } =
    useContext(ExpirationSessionGrowlContext);

  const isRemainingTimeInferiorToSixtySeconds =
    isDefined(remainingTimeInSeconds) && remainingTimeInSeconds < TWO_MINUTE;

  const hasGrowlAlreadyBeenDismissedOrClosedForThisSession =
    lastGrowlDismissedOrExpiredSessionTime === lastSessionStartTime;

  const showExpirationGrowl =
    isRemainingTimeInferiorToSixtySeconds && !hasGrowlAlreadyBeenDismissedOrClosedForThisSession;

  const closeGrowl = useCallback(() => {
    setLastGrowlDismissedOrExpiredSessionTime(lastSessionStartTime ?? undefined);
  }, [lastSessionStartTime, setLastGrowlDismissedOrExpiredSessionTime]);

  useEffect(() => {
    if (showExpirationGrowl) {
      setTimeout(() => {
        closeGrowl();
      }, 10000);
    }
  }, [closeGrowl, showExpirationGrowl]);

  if (remainingTimeInSeconds < 0) {
    return <h4 className="text-nowrap">--:--:--</h4>;
  }

  return (
    <>
      <ColoredRemainingTime remainingTimeInSeconds={remainingTimeInSeconds} />
      {showExpirationGrowl && (
        <Growl
          title="Session ending soon"
          right={growlRightPosition}
          top={growlTopPosition}
          testId={'expiration-growl'}
          bodyMessage={
            <FormattedMessage
              id={`growl.expirationSession`}
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          }
          onClose={closeGrowl}
          titleColor="danger"
        />
      )}
    </>
  );
};

const computeRemainingTime = (
  currentTimeString: string,
  lastSessionStartTime: PossibleSessionTime,
): number => {
  const currentTimeDate = DateTime.fromFormat(currentTimeString, 'hh:mm:ss');
  // this means we are out of range session opening hours or the previous session is more than 15 min old

  //todo could be handled more gracefully in a transformer at the API lvl
  const formattedLastSessionStartTime = lastSessionStartTime.startsWith('9')
    ? lastSessionStartTime.padStart(5, '0')
    : lastSessionStartTime;

  const closestPreviousSessionStartTimeDate = DateTime.fromFormat(
    `${formattedLastSessionStartTime}:00`,
    'hh:mm:ss',
  );

  const secondsSinceLastSessionStarted = currentTimeDate.diff(closestPreviousSessionStartTimeDate, [
    'seconds',
  ]).seconds;

  return FIFTEEN_MINUTES - secondsSinceLastSessionStarted;
};

const computeColorClassName = (remainingTimeInSeconds: number) => {
  let color = 'text-info';

  if (TWO_MINUTE <= remainingTimeInSeconds && remainingTimeInSeconds < FIVE_MINUTES) {
    color = 'text-warning';
  } else if (remainingTimeInSeconds < TWO_MINUTE) {
    color = 'text-danger';
  }
  return color;
};

const formatRemainingTime = (remainingTime: number): string => {
  const seconds = (remainingTime % 60).toString().padStart(2, '0');
  const minutes = Math.floor(remainingTime / 60)
    .toString()
    .padStart(2, '0');

  return `00:${minutes}:${seconds}`;
};

const ColoredRemainingTime = ({ remainingTimeInSeconds }: { remainingTimeInSeconds: number }) => (
  <>
    <h4
      data-e2e={'remaining-time-text'}
      className={`text-nowrap ${computeColorClassName(remainingTimeInSeconds)}`}
    >
      {formatRemainingTime(remainingTimeInSeconds)}
    </h4>
  </>
);
