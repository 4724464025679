import React, { FC } from 'react';
import './SavedDealsSelectionButton.css';
import { BookingStatus } from '@/features/vacation/vacationModel';

interface SavedDealsSelectionButtonProps {
  selectAllSavedDeals: (status: BookingStatus[]) => void;
  selectButtonLabel: string;
  isActive?: boolean;
}
const SavedDealsSelectionButton: FC<SavedDealsSelectionButtonProps> = ({
  selectAllSavedDeals,
  selectButtonLabel,
  isActive,
}) => (
  <button
    data-e2e={'saved-deals-selection-btn'}
    className={`btn ${
      isActive ? 'btn-primary-alt ' : 'btn-outline-info'
    } py-1 px-3 rounded-pill icon-saved-color-hover${isActive ? '-active' : ''}`}
    onClick={() => selectAllSavedDeals(['DONE'])}
  >
    <i role="img" className="icon-sm icon text-info px-1">
      {'done_all'}
    </i>
    {selectButtonLabel}
  </button>
);

export default SavedDealsSelectionButton;
