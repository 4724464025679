import { SendPayload, useSendDealsMutation } from '@/api/deals.api';
import { isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { useAppSelector } from '@/hooks/reduxHook';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

type SendButtonProps = {
  deals: SendPayload[];
  overrideClassName?: string;
  isFromBulkAction?: boolean;
};

function SendButton({ deals, overrideClassName, isFromBulkAction = false }: SendButtonProps) {
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const [sendDeals, { isLoading }] = useSendDealsMutation();

  const onSend: React.FormEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      event.preventDefault();
      sgwtWebAnalytics?.trackEvent(isFromBulkAction ? 'Bulk Send' : 'Send', 'User actions');
      await sendDeals(deals);
    },
    [sendDeals, deals, sgwtWebAnalytics, isFromBulkAction],
  );

  return (
    <button
      role="button"
      onClick={onSend}
      data-e2e={`send-${deals.length !== 1 ? 'deals' : deals[0].dealId}`}
      disabled={blotterReadOnly}
      className={overrideClassName ? overrideClassName : 'btn btn-md btn-discreet-success'}
    >
      {!isLoading ? (
        <FormattedMessage id={`blotter.${isFromBulkAction ? 'dealsBulkAction.' : ''}send`} />
      ) : (
        <div data-e2e="spinner" className="spinner spinner-sm" />
      )}
    </button>
  );
}

export default SendButton;
